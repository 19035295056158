import storage from './Storage'

/**
 * Application Class
 * this class handle app control and settings
 */

const app = {
  app_name: 'inLo',
  supper_key: '@app_system:',
  currency: '$',
  fonts: {
    en: 'Raleway, sans-serif !important;',
    fa: 'Tahoma, sans-serif !important;'
  },
  roles: {
    admin: '3a6603e6-5fc6-4a23-ba38-9c390a37ab07',
    business_manager: '62c96098-01ba-4a8c-b8ac-fc39050afd55',
    customer: '6da34c7e-7722-45bd-a92b-717848cbebd7',
    developer: '7bd8bf6e-8430-45cf-b97a-4c7768f3bef6',
    logger: '19e01346-5860-4b3e-9fa3-d61f28fd4d21',
    owner: '481a9400-d166-4971-bd5e-4ee60c3cfb71'
  },
  default_lang: 'en',
  test_data: false,
  languages: ['en', 'sv'],
  limit: 24,
  search_keyword_length: 3,
  rtl_lang: ['fa'],
  lang_items: [
    {
      key: 'sv',
      title: 'svenska'
    },
    {
      key: 'en',
      title: 'English'
    }
  ],

  // load data with key
  default_image: require('@/assets/img/default.png'),
  load(key) {
    return storage.get(this.supper_key + key)
  },

  // Save data with key
  save(key, value) {
    storage.set(this.supper_key + key, value)
  }
}

export default app
