<template>
  <count-selector
    :value="request.params.getLimit()"
    hide_title
    @input="e => change(e)"
  />
</template>

<script>
import { GetRequest } from '@/models/GetRequest'
import CountSelector from '@/components/selectors/CountSelector'

export default {
  name: 'ItemsLimit',
  components: { CountSelector },
  props: {
    request: {
      type: GetRequest,
      default: new GetRequest(),
      required: true
    }
  },
  data() {
    return {
      limit: null
    }
  },
  methods: {
    change(e) {
      this.request.setLimit(e)
      this.request.reload()
    }
  }
}
</script>
