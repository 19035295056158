<template>
  <div>
    <not-found-data v-if="!data || (data.length < 1)" style="padding-top: 90px !important;"/>
    <v-data-table
      v-else
      :headers="headers"
      :items="data"
      :items-per-page="15"
    >
      <template v-slot:item.coupon_id.expiration_date="{ item }">
        <date-text :data="item.coupon_id.expiration_date"></date-text>
      </template>
      <template v-slot:item.expiration_date="{ item }">
        <date-text :data="item.expiration_date"></date-text>
      </template>
      <template v-slot:item.total_count="{ item }">
        <span v-if="item.total_count">{{ item.total_count + ' / ' + item.used_count }}</span>
      </template>
      <template v-slot:item.discount_rate="{ item }">
        <span>{{ item.discount_rate + '%' }}</span>
      </template>
      <template v-slot:item.date_updated="{ item }">
        <span>{{ checkStatus(item) ? __('active') : __('expired') }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <coupon-actions :data="item" :usage="usage" @then="updateData" @archived="archivedData"></coupon-actions>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import DateText from '@/components/app/DateText'
import NotFoundData from '@/components/app/NotFoundData'
import CouponActions from '@/components/coupons/CouponActions'

export default {
  name: 'CouponsTable',
  components: { CouponActions, NotFoundData, DateText },
  props: {
    data: {
      type: Array,
      require: true
    },
    with_business_name: {
      type: Boolean,
      default: false
    },
    usage: {
      default: 'business',
      type: String
    }
  },
  data() {
    return {}
  },
  computed: {
    headers() {
      if (this.usage === 'customer') {
        return this.customerHeaders()
      } else if (this.usage === 'used') {
        return this.usedHeaders()
      } else {
        return this.businessHeaders()
      }
    }
  },
  mounted() {
  },
  methods: {
    usedHeaders() {
      return [
        {
          text: this.__('FirstName'),
          value: 'customer_id.user_id.first_name',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('LastName'),
          value: 'customer_id.user_id.first_name',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('Number'),
          value: 'number',
          align: 'start',
          sortable: false
        }
      ]
    },
    customerHeaders() {
      return [
        {
          text: this.__('BusinessName'),
          value: 'coupon_id.business_id.name',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('Title'),
          value: 'coupon_id.title',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('HowOften'),
          value: 'coupon_id.recurrence_type',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('Number'),
          value: 'number',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('ExpirationDate'),
          value: 'coupon_id.expiration_date',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('actions'),
          value: 'actions',
          align: 'start',
          sortable: false
        }
      ]
    },
    businessHeaders() {
      const result = [
        {
          text: this.__('Title'),
          value: 'title',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('HowOften'),
          value: 'recurrence_type',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('HowManyAvailable&Used'),
          value: 'total_count',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('DiscountRate'),
          value: 'discount_rate',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('ExpirationDate'),
          value: 'expiration_date',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('status'),
          value: 'date_updated',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('actions'),
          value: 'actions',
          align: 'start',
          sortable: false
        }
      ]

      if (this.with_business_name) {
        result.unshift(
          {
            text: this.__('BusinessName'),
            value: 'business_id.name',
            align: 'start',
            sortable: false
          }
        )
      }

      return result
    },
    checkStatus(item) {
      if (!item.expiration_date) return false
      const now = new Date().getTime()
      const status = new Date(item.expiration_date).getTime()

      return now < status
    },
    updateData(result) {
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].hasOwnProperty('id')) {
          if (this.data[i].id === result.id) {
            this.data[i] = result
          }
        }
      }
    },
    archivedData(result) {
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].hasOwnProperty('id')) {
          if (this.data[i].id === result.id) {
            this.data.splice(i, 1)
          }
        }
      }
    }
  }
}
</script>
