<template>
  <form v-if="request && request.body.name !== undefined" @submit.prevent="request.post()">
    <v-row>
      <v-col cols="12" md="6">
        <div class="font-weight-bold">{{ __('businessName') }}</div>
        <v-text-field
          v-model="request.body.name"
          dense
          outlined
          required
        />
      </v-col>

      <v-col cols="12" md="6">
        <category-selector v-model="request.body.category" :first_label="__('SelectOne')"/>
      </v-col>

      <v-col cols="12" md="6">
        <div class="font-weight-bold">{{ __('followers') }}</div>
        <v-text-field
          v-model="followers"
          disabled
          dense
          outlined
          required
        />
      </v-col>

      <v-col cols="12" md="6">
        <div class="font-weight-bold">{{ __('tags') }}</div>
        <v-text-field
          v-model="tags"
          :hint="__('separateWithCommas')"
          dense
          outlined
          required
        />
      </v-col>

      <v-col cols="12" md="6">
        <div class="font-weight-bold">{{ __('instagramID') }}</div>
        <v-text-field
          v-model="request.body.instagram"
          dense
          outlined
          required
        />
      </v-col>

      <v-col cols="12" md="6">
        <div class="font-weight-bold">{{ __('facebookID') }}</div>
        <v-text-field
          v-model="request.body.facebook"
          dense
          outlined
          required
        />
      </v-col>

      <v-col cols="12" md="6">
        <div class="font-weight-bold">{{ __('firstName') }}</div>
        <v-text-field
          v-model="request.body.first_name"
          disabled
          dense
          outlined
          required
        />
      </v-col>

      <v-col cols="12" md="6">
        <div class="font-weight-bold">{{ __('appleID') }}</div>
        <v-text-field
          disabled
          dense
          outlined
          required
        />
      </v-col>

      <v-col cols="12" md="6">
        <div class="font-weight-bold">{{ __('emailAddress') }}</div>
        <v-text-field
          v-model="request.body.email"
          disabled
          dense
          outlined
          required
        />
      </v-col>

      <v-col cols="12" md="6">
        <div class="font-weight-bold">{{ __('website') }}</div>
        <v-text-field
          v-model="request.body.website"
          type="url"
          dense
          outlined
          required
        />
      </v-col>

      <v-col cols="12" md="6">
        <div class="font-weight-bold">{{ __('phoneNumber') }}</div>
        <v-text-field
          v-model="request.body.phone_number"
          dense
          outlined
          required
        />
      </v-col>

      <v-col cols="12" md="6">
        <div class="font-weight-bold">{{ __('address') }}</div>
        <v-text-field
          v-model="request.body.address"
          dense
          outlined
          required
        />
      </v-col>

      <v-col cols="12" md="6" @dblclick="logBody">
        <!--        <div class="font-weight-bold">{{ __('hours') }}</div>-->
        <!--        <v-text-field-->
        <!--          v-model="request.body.opening_hours"-->
        <!--          disabled-->
        <!--          dense-->
        <!--          outlined-->
        <!--          required-->
        <!--        />-->
        <hour-selector v-model="request.body.opening_hours"></hour-selector>
      </v-col>

      <v-col cols="12" md="6">
        <div class="font-weight-bold">{{ __('whatAreWeKnownFor') }}</div>
        <v-text-field
          v-model="request.body.known_for"
          dense
          outlined
          required
        />
      </v-col>

      <v-col cols="12" md="6">
        <div class="font-weight-bold">{{ __('location') }}</div>
        <v-text-field
          :value="request.body.location.lat + ', ' + request.body.location.lng"
          disabled
          dense
          outlined
          required
          append-icon="mdi-pin"
        />
      </v-col>

      <v-col cols="12" md="6">
        <div class="font-weight-bold">{{ __('rating') }}</div>
        <v-text-field
          :value="request.body.rating"
          disabled
          :placeholder="__('SelectRating')"
          dense
          outlined
          required
        >
          <template v-slot:append>
            <v-rating
              v-model="request.body.rating"
              dense
              hover
              half-increments
              length="5"
              size="30"
            ></v-rating>
          </template>
        </v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <owner-selector v-model="request.body.owner_id" :first_label="__('SelectOne')"/>
      </v-col>

      <v-col cols="12" md="6">
        <status-selector v-model="request.body.status" :items="status_items"/>
      </v-col>

      <v-col cols="12">
        <div class="font-weight-bold">{{ __('document') }}</div>
        <assets-images :data="request.body.proof_of_ownership" class="rounded-lg" max-height="188" max-width="190">
          <upload accept="image/*" @then="uploaded"></upload>

          <template v-slot:image="{item, index}">
            <v-btn icon @click="removeImage(index)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </assets-images>
      </v-col>

      <v-col md="12">
        <v-btn type="submit" color="primary" elevation="0" :disabled="request.isLoading()">
          <span v-if="!request.is_loading">{{ __('Submit') }}</span>
          <span v-else>...</span>
        </v-btn>
      </v-col>

    </v-row>
  </form>
</template>

<script>
import { PostRequest } from '@/models/PostRequest'
import CategorySelector from '@/components/selectors/CategorySelector'
import OwnerSelector from '@/components/selectors/OwnerSelector'
import StatusSelector from '@/components/selectors/StatusSelector'
import HourSelector from '@/components/selectors/HourSelector'
import Upload from '@/components/assets/Upload'
import AssetsImages from '@/components/assets/AssetsImages'

export default {
  name: 'BusinessForm',
  components: { AssetsImages, Upload, HourSelector, StatusSelector, OwnerSelector, CategorySelector },
  props: {
    data: {
      default: null,
      type: [Array, Object],
      require: true
    }
  },
  data() {
    return {
      request: null,
      tags: '',
      followers: [],
      status_items: [
        {
          text: this.__('SelectOne'),
          value: null
        },
        {
          text: this.__('Active'),
          value: 'active'
        },
        {
          text: this.__('Pending'),
          value: 'pending'
        },
        {
          text: this.__('Archive'),
          value: 'archived'
        }
      ]
    }
  },
  mounted() {
    if (this.data) {
      this.request = new PostRequest('update_business')
    } else {
      this.request = new PostRequest('new_business')
    }

    this.request.setThen(this.submitted)
    this.setBody()
  },
  methods: {
    setBody() {
      this.request.setBody(
        {
          owner_id: null,
          address: null,
          category: null,
          name: null,
          tags: [],
          proof_of_ownership: [],
          status: null,
          known_for: null,
          history: null,
          facebook: null,
          instagram: null,
          utc_offset: null,
          rating: null,
          opening_hours: [],
          location: {
            lat: 40.73669719999999,
            lng: -74.16520489999999
          },
          latitude: 40.73669719999999,
          longitude: -74.16520489999999,
          city: null,
          website: null,
          map_url: null,
          phone_number: null
          // place_details: {
          //   address_components: [],
          //   adr_address: null,
          //   business_status: null,
          //   formatted_address: null,
          //   formatted_phone_number: null,
          //   geometry: {
          //     location: {
          //       lat: 40.73669719999999,
          //       lng: -74.16520489999999
          //     },
          //     viewport: {
          //       northeast: {
          //         lat: 40.73813808029149,
          //         lng: -74.1639683697085
          //       },
          //       southwest: {
          //         lat: 40.7354401197085,
          //         lng: -74.1666663302915
          //       }
          //     }
          //   },
          //   icon: null,
          //   icon_background_color: null,
          //   icon_mask_base_uri: null,
          //   international_phone_number: null,
          //   name: null,
          //   opening_hours: null,
          //   photos: [],
          //   place_id: null,
          //   plus_code: {
          //     compound_code: 'PRPM+MW Newark, NJ, USA',
          //     global_code: '87G7PRPM+MW'
          //   }, price_level: null,
          //   rating: null,
          //   reference: null,
          //   reviews: [],
          //   types: [],
          //   url: null,
          //   user_ratings_total: null,
          //   utc_offset: null,
          //   vicinity: null,
          //   website: null
          // }
        }
      )
    },
    submitted(data) {
      this.request.body.tags = this.tags.split(',')
      this.$emit('then', data)
      this.setBody()
    },
    uploaded(response) {
      try {
        if (!response || !response.data || !response.data.id) throw new Error('Not found any uploaded file id!')
        this.request.body.proof_of_ownership.push({ directus_files_id: response.data.id })
      } catch (e) {
        console.log(e)
      }
    },
    removeImage(index) {
      try {
        this.$delete(this.request.body.proof_of_ownership, index)
      } catch (e) {
        console.log(e)
      }
    },
    logBody() {
      console.log(this.request.body)
    }
  }
}
</script>

<style scoped>

</style>
