<template>
  <div>
    <EditModal
      :title="__('EditCustomerLimited')"
      v-bind="$attrs"
      @input="e => $emit('input', e)"
    >
      <customer-limited-form :fields="fields" :data="data" @then="e => $emit('then', e)"></customer-limited-form>
    </EditModal>
  </div>
</template>

<script>
import EditModal from '@/components/modal/EditModal'
import CustomerLimitedForm from '@/components/limiteds/CustomerLimitedForm'

export default {
  name: 'CustomerLimitedEdit',
  components: { CustomerLimitedForm, EditModal },
  props: {
    data: {
      default: null,
      type: Object
    },
    fields: {
      default: '*',
      type: String
    }
  },
  data: () => {
    return {}
  },

  computed: {},
  methods: {}
}
</script>

<style scoped>

</style>
