<template>
  <v-card class="text-center w-full">
    <v-card-title>
      Operators list
    </v-card-title>
    <v-card-text>
      <users
        :nin_role="ninRoles"
        add_is_visible
        hide_view
        filter_status
        with_date_login
      />
    </v-card-text>
  </v-card>
</template>

<script>

import Users from '@/components/users/Users'

export default {
  name: 'Operators',
  components: { Users },
  props: {},
  data() {
    return {}
  },
  computed: {
    ninRoles() {
      return [this._role('business_manager'), this._role('customer'), this._role('owner')]
    }
  },
  mounted() {
  },
  methods: {}
}
</script>
