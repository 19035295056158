<template>
  <v-dialog v-bind="$attrs" max-width="500" @input="e => $emit('input', e)">
    <v-card>
      <v-card-title>{{ __('images') }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">

            <v-img
              max-width="300"
              max-height="300"
              :src="selectedImg"
            ></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="(img,index) in data"
            :key="img+index"
            cols="2"
          >
            <v-img
              :class="selectedImg === getUrl(img) ? 'ma-1 border-light' : 'ma-1'"
              max-width="50"
              max-height="50"
              :src="getUrl(img)"
              @click="selectedImg = getUrl(img)"
            ></v-img>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="$emit('input', false)">{{ __('Close') }}</v-btn>
        <v-spacer/>
        <v-btn v-if="selectedImg" :href="selectedImg" target="_blank">{{ __('Download') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import app from '@/tools/App'
import urls from '@/tools/Urls'

export default {
  name: 'ImagesModal',
  components: {},
  inheritAttrs: false,
  props: {
    data: {
      default: null,
      type: Array
    },
    quality: {
      default: 90,
      type: [Number, String]
    },
    type: {
      default: 'cover',
      type: String
    }
  },
  data() {
    return {
      selectedImg: null
    }
  },
  mounted() {

  },
  methods: {

    getUrl(item) {
      try {
        const url = urls.api('assets_url', [item.directus_files_id])

        let params = `?fit=${this.type}`

        if (this.width) params += `&width=${this.width}`
        if (this.height) params += `&height=${this.height}`
        if (this.quality) params += `&quality=${this.quality}`

        return url + params
      } catch (e) {
        return app.default_image
      }
    }
  }
}
</script>

<style scoped>

</style>
