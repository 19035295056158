<template>
  <v-app id="panel-app">

    <!--  App Bar  -->
    <v-app-bar
      fixed
      app
      color="background"
      elevation="0"
      clipped-left
      height="80"
    >
      <v-app-bar-nav-icon class="hidden-lg-and-up" @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>
        <router-link to="/">
          <logo/>
        </router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <account/>
    </v-app-bar>

    <!-- Drawer   -->
    <v-navigation-drawer
      v-model="drawer"
      clipped
      width="290"
      app
    >

      <v-list>
        <v-list-item-group color="primary">
          <template v-for="(item, key) in menu">
            <v-list-item
              v-if="(item.not_role) ? !_access(item.not_role) : _access(item.role)"
              :key="'header-drawer-'+key"
              :to="item.to"
              :disabled="!item.to"
              active-class="secondary "
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>

    </v-navigation-drawer>

    <!--  Main  -->
    <v-main class="pb-7">
      <router-view></router-view>
    </v-main>

  </v-app>

</template>

<script>
import Logo from '@/components/app/Logo'
import Account from '@/components/account/Account'

export default {
  name: 'PanelLayout',
  components: { Account, Logo },

  data() {
    return {
      drawer: null
    }
  },
  computed: {
    menu() {
      return [
        {
          title: this.__('Dashboard'),
          to: { name: 'Dashboard' },
          icon: 'mdi-view-dashboard'
        },

        // user pages
        {
          title: this.__('AdminOperator'),
          to: { name: 'Operators' },
          icon: 'mdi-account-tie-outline'
        },
        {
          title: this.__('BusinessOwnerManager'),
          to: { name: 'BusinessOwners' },
          icon: 'mdi-account-check-outline'
        },
        {
          title: this.__('Customers'),
          to: { name: 'Customers' },
          icon: 'mdi-account-group-outline'
        },

        // business pages
        {
          title: this.__('Business'),
          to: { name: 'Businesses' },
          icon: 'mdi-store-outline'
        },
        {
          title: this.__('Coupons'),
          to: { name: 'Coupons' },
          icon: 'mdi-ticket-percent-outline'
        },
        {
          title: this.__('LimitedItem'),
          to: { name: 'Limiteds' },
          icon: 'mdi-tag-multiple-outline'
        },

        // other pages
        {
          title: this.__('Feedback'),
          to: { name: 'Feedbacks' },
          icon: 'mdi-message-alert-outline'
        },
        {
          title: this.__('Referral'),
          to: { name: 'Referrals' },
          icon: 'mdi-account-star-outline'
        },
        {
          title: this.__('SubscriptionLog'),
          // to: { name: 'Subscriptions' },
          icon: 'mdi-youtube-subscription'
        }
        //
        // // { title: this.__('Professionals'), to: { name: 'Professionals' }, icon: 'mdi-account-hard-hat', not_role: 'pro' },
        // { title: this.__('Clients'), to: { name: 'Clients' }, icon: 'mdi-account-box', role: 'admin' },
      ]
    }

  },
  methods: {}
}
</script>

<style>
#panel-app .v-navigation-drawer__border {
  background-color: rgba(0, 0, 0, 0.05);
  width: 1px;
  border: none;
  outline: none;
}
</style>
