<template>
  <div class="text-center w-full">
    <v-tabs>
      <v-tab @click="getUpdates">{{ __('Update') }}</v-tab>
      <v-tab @click="getCoupons">{{ __('Coupons') }}</v-tab>
      <v-tab @click="getLimited">{{ __('LimitedItem') }}</v-tab>
      <v-tab @click="getSuggestion">{{ __('SuggestionBox') }}</v-tab>
      <v-tab @click="getManager">{{ __('BusinessManager') }}</v-tab>
    </v-tabs>
    <!-- Loading -->
    <loading v-if="request && request.isLoading()" :count="loader_count"/>

    <updates v-if="selected_list === 'business_update'" :business_id="id"></updates>
    <coupons v-if="selected_list === 'business_coupons'" :id="id"></coupons>
    <limiteds v-if="selected_list === 'business_limited'" :id="id"></limiteds>
    <suggestions v-if="selected_list === 'business_suggestion'" :business_id="id"></suggestions>
    <Managers v-if="selected_list === 'business_manager'" :request="request" :fields="fields"></Managers>
  </div>
</template>

<script>

import Loading from '@/components/app/Loading'
import { GetRequest } from '@/models/GetRequest'
import Updates from '@/components/updates/Updates'
import Coupons from '@/components/coupons/Coupons'
import Limiteds from '@/components/limiteds/Limiteds'
import Suggestions from '@/components/suggestions/Suggestions'
import Managers from '@/components/businesses/Managers'

export default {
  name: 'BusinessLists',
  components: {
    Managers,
    Suggestions,
    Limiteds,
    Coupons,
    Updates,
    Loading
  },
  props: {
    request: {
      type: GetRequest,
      default: new GetRequest()
    },
    id: {
      default: 12,
      type: [String, Number]
    },
    loader_count: {
      default: 12,
      type: [String, Number]
    }
  },
  data() {
    return {
      selected_list: '',
      fields: '*',
      sort: 'date_created',
      limit: null
    }
  },
  computed: {},
  mounted() {
    this.getUpdates()
  },
  methods: {
    getUpdates() {
      this.selected_list = 'business_update'
    },
    getCoupons() {
      this.selected_list = 'business_coupons'
    },
    getLimited() {
      this.selected_list = 'business_limited'
    },
    getSuggestion() {
      this.selected_list = 'business_suggestion'
    },
    getManager() {
      this.selected_list = 'business_manager'
    }
  }
}
</script>
