<template>
  <v-card class="text-center w-full">
    <v-card-title>
      New Business
    </v-card-title>
    <v-card-text>
      <business-form></business-form>
    </v-card-text>
  </v-card>
</template>

<script>

import BusinessForm from '@/components/businesses/BusinessForm'

export default {
  name: 'NewBusiness',
  components: { BusinessForm },
  props: {},
  data() {
    return {}
  },
  computed: {},
  mounted() {
  },
  methods: {}
}
</script>
