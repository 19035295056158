<template>
  <v-card flat class="ma-8">
    <v-row>
      <v-col cols="4">
        <v-avatar size="100%" color="secondary">
          <assets-image v-if="_user.avatar && (_user.avatar.length > 0)" :data="_user.avatar" width="64"/>
          <v-icon v-else color="grey lighten-1" size="64">mdi-account</v-icon>
        </v-avatar>
        <v-btn plain>{{ __('editAvatar') }}</v-btn>
      </v-col>
      <v-col cols="8">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <full-name :data="_user"></full-name>
            </v-list-item-title>
            {{ _user.email }}
            <div v-if="_user.role && typeof _user.role === 'object'">{{ _user.role.name }}</div>
            <v-btn rounded outlined disabled>{{ __('edit') }}</v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row class="mt-6">
      <v-col cols="4">
        {{ __('lastSignIn') }}:
        <date-text :data="_user.last_access"></date-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import AssetsImage from '@/components/assets/Image'
import FullName from '@/components/account/FullName'
import DateText from '@/components/app/DateText'

export default {
  name: 'AccountDetails',
  components: { DateText, FullName, AssetsImage },
  data() {
    return {
      menu: null
    }
  },

  computed: {
    user() {
      return this.$store.state.user_info
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>
