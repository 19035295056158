<template>
  <div>
    <coupons add_is_visible fields="*,business_id.name"></coupons>
  </div>
</template>

<script>
import Coupons from '@/components/coupons/Coupons'

export default {
  name: 'BusinessesPage',
  components: { Coupons },
  data() {
    return {}
  },
  methods: {}
}
</script>
