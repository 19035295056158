<template>
  <v-card class="text-center w-full">
    <v-card-title>
      Business Owners list
    </v-card-title>
    <v-card-text>
      <Users
        :in_role="inRoles"
        :add_is_visible="false"
        hide_edit
        filter_status
        with_date_login
        with_date_register
      />
    </v-card-text>
  </v-card>
</template>

<script>

import Users from '@/components/users/Users'

export default {
  name: 'BusinessOwners',
  components: { Users },
  props: {},
  data() {
    return {}
  },
  computed: {
    inRoles() {
      return [this._role('business_manager'), this._role('owner')]
    }
  },
  mounted() {
  },
  methods: {}
}
</script>
