<template>
  <v-app id="site-app">
    <v-main app>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'AuthLayout',
  components: {},
  data() {
    return {
      drawer: null,
    }
  }
}
</script>

<style>
</style>