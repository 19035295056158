<template>
  <form v-if="request && request.body.number !== undefined" @submit.prevent="request.patch()">
    <v-row>
      <v-col cols="12" md="6">
        <div class="font-weight-bold">{{ __('Number') }}</div>
        <v-text-field
          v-model="request.body.number"
          dense
          outlined
          required
        />
      </v-col>

      <v-col cols="12" md="6">
        <status-selector v-model="request.body.status" :items="status_items"/>
      </v-col>

      <v-col md="12">
        <v-btn type="submit" color="primary" elevation="0" :disabled="request.isLoading()">
          <span v-if="!request.is_loading">{{ __('Save') }}</span>
          <span v-else>...</span>
        </v-btn>
      </v-col>

    </v-row>
  </form>
</template>

<script>
import { PostRequest } from '@/models/PostRequest'
import StatusSelector from '@/components/selectors/StatusSelector'

export default {
  name: 'CustomerLimitedForm',
  components: { StatusSelector },
  props: {
    data: {
      default: null,
      type: [Array, Object],
      require: true
    },
    fields: {
      default: '*',
      type: String
    }
  },
  data() {
    return {
      request: new PostRequest(),
      status_items: [
        {
          text: this.__('Reserved'),
          value: 'reserved'
        },
        {
          text: this.__('Claimed'),
          value: 'claimed'
        },
        {
          text: this.__('Expired'),
          value: 'expired'
        }
      ]
    }
  },
  mounted() {
    if (this.data) {
      this.request.setApi('update_customer_limited')
      this.request.setApiParams([this.data.id])
    } else {
      this.request.setApi('new_customer_limited')
    }

    this.request.setThen(this.submitted)
    this.request.setFields(this.fields)
    this.setBody()
  },
  methods: {
    setBody() {
      if (this.data) {
        this.request.setBody(
          {
            number: this.data.number,
            status: this.data.status
          }
        )
      } else {
        this.request.setBody(
          {
            number: null,
            status: null
          }
        )
      }
    },
    submitted(data) {
      this.$emit('then', data.data)
    }
  }
}
</script>

<style scoped>

</style>
