<template>
  <div>
    <not-found-data v-if="!data || (data.length < 1)" style="padding-top: 90px !important;"/>
    <v-data-table
      v-else
      :headers="headers"

      :items="data"
      :items-per-page="15"
    >
      <template v-slot:item.actions="{ item }">
        <referral-actions :data="item" @then="updateData" @archived="archivedData"></referral-actions>
      </template>
    </v-data-table>

  </div>
</template>

<script>
import ReferralActions from '@/components/referrals/ReferralActions'
import NotFoundData from '@/components/app/NotFoundData'

export default {
  name: 'ReferralsTable',
  components: { NotFoundData, ReferralActions },
  props: {
    data: {
      type: Array,
      require: true
    }
  },
  data() {
    return {
      show_images: false,
      selected_images: null
    }
  },
  computed: {
    headers() {
      const result = [
        {
          text: this.__('FirstName'),
          value: 'user_id.first_name',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('LastName'),
          value: 'user_id.last_name',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('ReferredTo'),
          value: 'referred_to',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('actions'),
          value: 'actions',
          align: 'start',
          sortable: false
        }
      ]

      return result
    }
  },
  mounted() {
  },
  methods: {
    updateData(result) {
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].hasOwnProperty('id')) {
          if (this.data[i].id === result.id) {
            this.data[i] = result
          }
        }
      }
    },
    archivedData(result) {
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].hasOwnProperty('id')) {
          if (this.data[i].id === result.id) {
            this.data.splice(i, 1)
          }
        }
      }
    }
  }
}
</script>
