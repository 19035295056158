<template>
  <div class="text-center" v-bind="$attrs">
    <div class="py-7 text-center text-h5 text--disabled">{{ title || __('NotFoundData') }}</div>
    <div v-if="text" class="text--disabled mx-auto" style="max-width: 600px">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: 'NotFoundData',
  props: {
    title: {
      default: null,
      type: [String]
    },
    text: {
      default: null,
      type: [String]
    }
  }
}
</script>

<style scoped>

</style>
