<template>
  <div>
    <v-btn
      class="rounded-lg border-light"
      outlined
      large
      @click="show_confirm = !show_confirm"
    >
      <v-icon left>mdi-download</v-icon>
      {{ __("Export") }}
    </v-btn>
    <confirm
      v-model="show_confirm"
      :title="__('ExportReferrals')"
      :text="__('AreYouSure')"
      error_color="success"
      @then="submit"
    >
    </confirm>
  </div>
</template>

<script>
import { PostRequest } from '@/models/PostRequest'
import Confirm from '@/components/modal/Confirm'

export default {
  name: 'ReferralExport',
  components: { Confirm },
  props: {},
  data: () => {
    return {
      request: new PostRequest('export_referral'),
      show_confirm: false
    }
  },
  mounted() {

  },
  methods: {
    setBody() {
      this.request.setThen(this.submitted)
      this.request.setBody(
        {
          format: 'xml',
          query: {
            filter: {
              status: {
                _eq: 'published'
              }
            }
          }
        }
      )
    },
    submit(e) {
      if (e) {
        this.setBody()
        this.request.post()
      }
      this.$emit('input', e)
    },
    submitted() {

    }
  }
}
</script>

<style scoped>

</style>
