// En
// English messages



module.exports = {
    'inLo' : "inLo",
    'SignIn' : "Sign In",
    'ToinLo' : "To inLo",
    'Email' : "Email",
    'Password' : "Password",
};
