<template>
  <v-card class="text-center w-full">
    <v-card-title>
      Referral list
    </v-card-title>
    <v-card-text>

      <v-row v-if="!hide_actions">
        <v-col class="d-flex justify-space-between">
          <div class="d-flex width-full">

            <!-- Search -->
            <referral-search :fields="fields" :request="request"></referral-search>
          </div>

          <space/>

          <div class="d-flex">
            <!-- Limit -->
            <items-limit :request="request"/>
          </div>

          <!--   Export button     -->
          <referral-export></referral-export>
        </v-col>
      </v-row>

      <!-- Loading -->
      <loading v-if="request.isLoading()" :count="loader_count"/>

      <referrals-table :data="request.data"></referrals-table>
    </v-card-text>
  </v-card>
</template>

<script>

import Loading from '@/components/app/Loading'
import { GetRequest } from '@/models/GetRequest'
import Space from '@/components/app/Space'
import ItemsLimit from '@/components/items/ItemsLimit'
import ReferralSearch from '@/components/referrals/ReferralSearch'
import ReferralsTable from '@/components/referrals/ReferralsTable'
import ReferralExport from '@/components/referrals/ReferralExport'

export default {
  name: 'Referrals',
  components: { ReferralExport, ReferralsTable, ReferralSearch, ItemsLimit, Space, Loading },
  props: {
    loader_count: {
      default: 12,
      type: [String, Number]
    },
    hide_actions: {
      default: false,
      type: Boolean
    },
    limit: {
      default: 5,
      type: [String, Number]
    },
    sort: {
      default: 'date_created',
      type: String
    },
    fields: {
      default: '*,user_id.first_name,user_id.last_name',
      type: String
    }
  },
  data() {
    return {
      request: new GetRequest('get_referrals'),
      show_filters: false,
      status: null
    }
  },
  computed: {},
  mounted() {
    this.request.setParam('meta', 'filter_count')
    this.get()
  },
  methods: {

    get() {
      this.request.reset()
      this.request.setLimit(this.limit)
      this.request.setSort(this.sort)
      this.request.setFields(this.fields)
      this.request.get()
    }
  }
}
</script>
