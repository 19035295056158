<template>
  <v-app>
    <v-container fluid>
      <messages/>
      <router-view/>
    </v-container>
  </v-app>
</template>

<script>

import Messages from "@/components/app/Messages";
import auth from "@/tools/Auth";

export default {
  name: 'App',
  components: {Messages},
  data: () => ({

  }),
  mounted() {
    this.$store.commit('setUserInfo', auth.getUserInfo())
  }
};
</script>
<style src='./assets/css/main.css'></style>