<template>
  <div>
    <coupon-details :id="$route.params.id"></coupon-details>
  </div>
</template>

<script>
import CouponDetails from '@/components/coupons/CouponDetails'

export default {
  name: 'CouponPage',
  components: { CouponDetails },
  data() {
    return {}
  },
  methods: {}
}
</script>
