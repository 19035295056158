<template>
  <div>

    <v-row v-if="!hide_actions">
      <v-col class="d-flex justify-space-between">
        <div class="d-flex width-full">

          <!-- Add Button   -->
          <template v-if="add_is_visible">
            <v-btn
              color="primary"
              elevation="0"
              large
              class="hidden-sm-and-down rounded-lg"
              :to="{name:'NewOperator'}"
            >
              {{ __('AddNewUser') }}
            </v-btn>
            <v-btn
              color="primary"
              fab
              elevation="0"
              small
              class="hidden-md-and-up"
              :to="{name:'AddUser'}"
              :title="__('AddNewUser')"
            >
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>

          <space/>

          <!-- Search -->
          <users-search :request="request"></users-search>
        </div>

        <space/>

        <div class="d-flex">
          <!-- Limit -->
          <items-limit :request="request"/>
        </div>

        <!--   Filters button     -->
        <v-btn
          v-if="!hide_filters"
          class="rounded-lg border-light"
          outlined
          large
          @click="show_filters = !show_filters"
        >
          <v-icon left>mdi-filter-variant</v-icon>
          {{ __("Filters") }}
        </v-btn>
      </v-col>
    </v-row>

    <!-- Filters -->
    <v-row v-show="show_filters" v-if="!hide_filters">
      <v-col>
        <users-filters
          :request="request"
          :with_date_login="with_date_login"
          :with_date_register="with_date_register"
          with_status
          @reset="get"
        >
        </users-filters>
      </v-col>
    </v-row>

    <!-- Loading -->
    <loading v-if="request.isLoading()" :count="loader_count"/>

    <!-- List -->
    <div v-else class="mt-4 px-0">
      <!--  Table    -->
      <v-row v-if="table">
        <v-col cols="12">
          <users-table :data="request.data" :hide_view="hide_view" :hide_edit="hide_edit" :usage="usage"/>
        </v-col>
      </v-row>

      <!--   Card   -->
      <v-row v-else>
        <v-col
          v-for="(item, key) in request.data"
          :key="'user-card-'+key"
          cols="6"
          md="3"
          sm="4"
          lg="2"
        >
          <user-card :data="item"/>
        </v-col>
      </v-row>

      <!--   Not found data   -->
      <not-found-data v-if="!request.data" style="padding-top: 90px !important;"/>

      <!-- Load More -->
      <load-more v-if="!hide_load_more" :request="request" pagination/>
    </div>

  </div>

</template>

<script>
import { GetRequest } from '@/models/GetRequest'

import LoadMore from '@/components/app/LoadMore'
import Space from '@/components/app/Space'
import NotFoundData from '@/components/app/NotFoundData'
import UserCard from '@/components/users/UserCard'
import Loading from '@/components/app/Loading'
import ItemsLimit from '@/components/items/ItemsLimit'
import UsersTable from './UsersTable'
import UsersFilters from '@/components/users/UsersFilters'
import UsersSearch from '@/components/users/UsersSearch'

export default {
  name: 'Users',
  components: {
    UsersSearch,
    UsersFilters,
    UsersTable,
    ItemsLimit,
    Loading,
    UserCard, NotFoundData, Space, LoadMore
  },
  props: {
    loader_count: {
      default: 12,
      type: [String, Number]
    },
    status: {
      default: null,
      type: String
    },
    add_is_visible: {
      default: false,
      type: Boolean
    },
    hide_actions: {
      default: false,
      type: Boolean
    },
    hide_filters: {
      default: false,
      type: Boolean
    },
    hide_load_more: {
      default: false,
      type: Boolean
    },
    table: {
      default: true,
      type: Boolean
    },
    hide_view: {
      default: false,
      type: Boolean
    },
    usage: {
      default: 'operator',
      type: String
    },
    hide_edit: {
      default: false,
      type: Boolean
    },
    filter_status: {
      default: true,
      type: Boolean
    },
    with_date_login: {
      default: false,
      type: Boolean
    },
    with_date_register: {
      default: false,
      type: Boolean
    },
    without: {
      default: null,
      type: Array
    },
    nin_role: {
      default: null,
      type: [String, Object, Array]
    },
    in_role: {
      default: null,
      type: [String, Object, Array]
    },
    role: {
      default: null,
      type: [String, Object, Array]
    },
    limit: {
      default: null,
      type: [String, Number]
    },
    sort: {
      default: 'last_access',
      type: String
    },
    fields: {
      default: '*, role.*',
      type: String
    }
  },
  data() {
    return {
      request: new GetRequest('get_users'),
      show_filters: false,
      selected_status: null,
      loaderAttrs: {
        class: '',
        boilerplate: false,
        elevation: 1
      }
    }
  },
  mounted() {
    this.get()
  },
  methods: {
    get() {
      this.request.reset()

      this.setFilters()
      this.setQueries()

      this.request.setLimit(this.limit)
      this.request.setNotIn('id', this.without)
      this.request.setSort(this.sort)
      this.request.setFields(this.fields)
      this.request.get()
    },
    setFilters() {
      if (this.status) this.request.addFilter('status', this.status)
      if (this.nin_role) this.request.addFilter('role', this.nin_role, '_nin')
      if (this.in_role) this.request.addFilter('role', this.in_role, '_in')
      if (this.role) this.request.addFilter('role', this.role, '_eq')
    },
    setQueries() {
      try {
        const queries = this.$route.query

        if (!queries) return

        if (queries.status) this.request.addFilter('status', queries.status)
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style scoped>

</style>
