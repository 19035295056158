<template>
  <div>
    <v-dialog
      max-width="400px"
      hide-overlay
      persistent
      :value="value"
      transition="dialog-bottom-transition"
      v-bind="$attrs"
      @input="e => $emit('input', e)"
    >
      <v-card v-if="data.user_created">
        <v-card-title class="text-h5">
          <full-name :data="data.user_created"></full-name>
        </v-card-title>
        <v-card-text>
          {{ data.text }}
        </v-card-text>
        <v-card-actions>
          <v-btn @click="result(false)">
            {{ __('Cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" :href="'mailto:' + data.user_created.email" target="_blank" @click="result(false)">
            {{ __('Email') }}
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import FullName from '@/components/account/FullName'

export default {
  name: 'FeedbackAnswer',
  components: { FullName },
  props: {
    data: {
      default: null,
      type: Object,
      require: true
    },
    value: {
      default: null,
      type: Boolean
    }
  },
  data: () => {
    return {}
  },
  mounted() {

  },
  methods: {
    result(value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped>

</style>
