<template>
  <input
    v-model="request.search_keyword"
    :placeholder="placeholder ? placeholder : __('Search')"
    class="px-4 py-2 rounded-lg border-light width-full"
    style="max-width: 350px"
    @input="changeInput($event.target.value)"
    @keyup.enter="search"
  />
</template>

<script>
import { GetRequest } from '@/models/GetRequest'
import { Parameters } from '@/models/Parameters'
import { Filters } from '@/models/Filters'

export default {
  name: 'LimitedSearch',
  props: {
    request: {
      type: GetRequest,
      default: new GetRequest(),
      required: true
    },
    usage: {
      default: 'business',
      type: String
    },
    id: {
      default: null,
      type: String
    },
    placeholder: {
      default: null,
      type: String
    },
    fields: {
      default: '*',
      type: String
    }
  },
  data() {
    return {
      parameters: new Parameters()
    }
  },
  methods: {
    changeInput(value) {
      this.parameters.set('search', value)
      if (value.length > 3) this.search()
    },
    search() {
      if (!this.request.search_keyword) return
      if (this.request.search_keyword.length < 1) return this.request.reset()
      if (this.usage === 'business') {
        this.request.setParameters(this.parameters)
      } else {
        this.setFilters()
      }
      this.request.setFields(this.fields)
      this.request.get()
    },
    setFilters() {
      let filters = null

      if (this.usage === 'customer') {
        filters = {
          '_and': [
            { 'customer_id': { '_eq': this.id } },
            {
              '_or': [
                { 'item_id': { 'business_id': { 'name': { '_contains': this.request.search_keyword } } } },
                { 'item_id': { 'title': { '_contains': this.request.search_keyword } } }]
            }]
        }
      } else if (this.usage === 'used') {
        filters = {
          '_and': [
            { 'item_id': { '_eq': this.id } },
            {
              '_or': [
                { 'customer_id': { 'user_id': { 'first_name': { '_contains': this.request.search_keyword } } } },
                { 'customer_id': { 'user_id': { 'last_name': { '_contains': this.request.search_keyword } } } },
                { 'customer_id': { 'user_id': { 'email': { '_contains': this.request.search_keyword } } } }
              ]
            }
          ]
        }
      }
      const filter = new Filters()

      filter.default_operation_items = filters
      this.request.setFilter(filter)
    }
  }
}
</script>

<style scoped>

</style>
