import app from '../tools/App'
import auth from '../tools/Auth'
import Trans from '../tools/Trans'
import router from './index'

function getUserRole() {
  try {
    const user = auth.getUserInfo()
    let { role } = user

    if (typeof role === 'object') role = role.id

    return role
  } catch (e) {
    return null
  }
}

// Require auth
router.beforeEach((to, from, next) => {
  if (!to.matched.some((record) => record.meta.requiresAuth)) return next()

  if (!auth.check()) {
    return next({ name: 'Login', query: { redirect: to.fullPath } })
  }

  next()
})

// Requires Role
router.beforeEach((to, from, next) => {
  if (!to.matched.some((record) => record.meta.requiresRole)) return next()

  if (getUserRole() === app.roles.pending) {
    return next({ name: 'CompleteRegister', query: { redirect: to.fullPath } })
  }

  next()
})

// Require guest
router.beforeEach((to, from, next) => {
  if (!to.matched.some((record) => record.meta.requiresGuest)) return next()
  if (auth.check())
    return next({ name: 'Dashboard' })
  next()
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.headTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle)
    document.title = nearestWithTitle.meta.title + ' | ' + app.app_name

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll('[data-vue-router-controlled]')
  ).map((el) => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.headTags
    .map((tagDef) => {
      const tag_name = tagDef['tag_name'] ? tagDef['tag_name'] : 'meta'

      if (tagDef['tag_name']) delete tagDef.tag_name

      const tag = document.createElement(tag_name)

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key])
      })

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '')

      return tag
    })

    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag))

  // Set lang
  document.getElementsByTagName('html')[0].setAttribute('lang', Trans.getLang())
  next()
})
