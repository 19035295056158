<template>
  <div>
    <edit-operator :id="$route.params.id"></edit-operator>
  </div>
</template>

<script>
import EditOperator from '@/components/operators/EditOperator'

export default {
  name: 'EditOperatorPage',
  components: { EditOperator },
  data() {
    return {}
  },
  methods: {}
}
</script>
