<template>
  <span v-if="data" :title="title">{{ date }}</span>
</template>

<script>
import moment from 'moment'

export default {
  name: 'DateText',
  props: {
    data: {
      default: null,
      type: [String, Object]
    }
  },
  data() {
    return {}
  },
  computed: {
    date() {
      try {
        return moment(this.data).fromNow()
      } catch (e) {
        return '-'
      }
    },
    title() {
      try {
        const created_at = new Date(this.data)
        const date = created_at.getFullYear() + '-' + (created_at.getMonth() + 1) + '-' + created_at.getDate()
        const time = created_at.getHours() + ':' + created_at.getMinutes() + ':' + created_at.getSeconds()

        return date + ' ' + time
      } catch (e) {
        return '-'
      }
    }
  }

}
</script>

<style scoped>

</style>
