<template>
  <div>
    <dashboard></dashboard>
  </div>
</template>

<script>
import Dashboard from '@/components/dashboard/Dashboard'

export default {
  name: 'DashboardPage',
  components: { Dashboard },
  data() {
    return {}
  },
  methods: {}
}
</script>
