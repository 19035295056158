<template>
  <v-card v-if="data" class="w-full">
    <v-card-text>
      <v-row>
        <v-col cols="3">
          <span @click="show_images = !show_images">
            <assets-images
              v-if="data.images.length>0"
              type="cover"
              :data="data.images"
              carousel
              hide_delimiters
            ></assets-images>
          </span>
          <v-btn disabled>
            {{ __('uploadPicture') }}
          </v-btn>
        </v-col>
        <v-col>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ data.name }}
                <v-list-item-subtitle>
                  <v-rating half-increments readonly :value="data.rating"></v-rating>
                  <div>
                    <strong>{{ __('Category') }}</strong>:{{ data.category.title }}
                  </div>
                  {{ data.website }}
                </v-list-item-subtitle>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn disabled>
                <v-icon>mdi-download</v-icon>
                {{ __('Photo') }}
              </v-btn>
              <v-btn disabled>
                <v-icon>mdi-download</v-icon>
                {{ __('Certification') }}
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <div>
                <strong>{{ __('PhoneNumber') }}</strong>:{{ data.phone_number }}
              </div>
              <div>
                <strong>{{ __('Address') }}</strong>:{{ data.phone_number }}
              </div>
              <div>
                <hour-selector readonly :value="data.opening_hours"></hour-selector>
              </div>
            </v-list-item-content>

            <v-list-item-action-text>
              <v-btn disabled icon target="_blank" :href="data.facebook">
                <v-icon>mdi-facebook</v-icon>
              </v-btn>
              <v-btn disabled icon target="_blank" :href="data.instagram">
                <v-icon>mdi-instagram</v-icon>
              </v-btn>
            </v-list-item-action-text>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <div>
                <strong>{{ __('History') }}</strong>:{{ data.history }}
              </div>
              <div>
                <strong>{{ __('WhatAreWeKnownFor?') }}</strong>:{{ data.known_for }}
              </div>
            </v-list-item-content>
            <v-list-item-action-text>
              <v-btn disabled small>
                <v-icon small>mdi-pencil</v-icon>
                {{ __('Edit') }}
              </v-btn>
            </v-list-item-action-text>
          </v-list-item>
        </v-col>
        <images-modal
          v-model="show_images"
          :data="data.images"
        ></images-modal>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

import AssetsImages from '@/components/assets/AssetsImages'
import HourSelector from '@/components/selectors/HourSelector'
import ImagesModal from '@/components/assets/ImagesModal'

export default {
  name: 'Business',
  components: { ImagesModal, HourSelector, AssetsImages },
  props: {
    data: {
      default: null,
      type: Object
    }
  },
  data() {
    return {
      show_images: false
    }
  },
  computed: {},
  mounted() {

  },
  methods: {}
}
</script>

<style scoped>

</style>
