<template>
  <v-card flat class="width-full">
    <v-row v-if="request.data">
      <v-col cols="2" offset="3">
        <v-avatar v-if="request.data.avatar === null" size="130" color="secondary">
          <assets-image
            v-if="request.data.avatar && (request.data.avatar.length > 0)"
            :data="request.data.avatar"
            width="64"
          />
          <v-icon v-else color="grey lighten-1" size="64">mdi-account</v-icon>
        </v-avatar>
      </v-col>
      <v-col cols="6">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <full-name :data="request.data"></full-name>
            </v-list-item-title>
            <div>
              {{ request.data.email }}
            </div>

            <div class="mt-5">
              {{ __('Role') }}:<strong v-if="request.data.role && typeof request.data.role === 'object'">{{
                request.data.role.name
              }}</strong>
            </div>

            <div class="mt-5">
              {{ __('lastSignIn') }}:
              <date-text :data="request.data.last_access"></date-text>
            </div>
            <div class="mt-3">
              <v-btn rounded outlined :href="'mailto:' + request.data.email">{{ __('email') }}</v-btn>
              <v-btn
                disabled
                rounded
                outlined
                :to="{name:'NewBusiness'}"
              >{{ __('createBusiness') }}
              </v-btn>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row v-if="request_businesses" class="mt-6">
      <v-col cols="12">
        <business-table :data="request_businesses.data"></business-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import FullName from '@/components/account/FullName'
import AssetsImage from '@/components/assets/Image'
import DateText from '@/components/app/DateText'
import { GetRequest } from '@/models/GetRequest'
import BusinessTable from '@/components/businesses/BusinessTable'

export default {
  name: 'UserDetails',
  components: { BusinessTable, DateText, AssetsImage, FullName },
  props: {
    id: {
      default: '',
      type: String,
      require: true
    }
  },
  data() {
    return {
      request: new GetRequest('get_user', [this.id]),
      request_businesses: new GetRequest('get_businesses')
    }
  },
  computed: {},
  mounted() {
    this.request.setThen(this.getBusiness)
    this.get()
  },
  methods: {

    get() {
      this.request.reset()

      this.setFilters()
      this.setQueries()

      this.request.setLimit(this.limit)
      this.request.setNotIn('id', this.without)
      this.request.setSort(this.sort)
      this.request.setFields(this.fields)
      this.request.row()
    },
    setFilters() {
      if (this.status) this.request.addFilter('status', this.status)
      if (this.nin_role) this.request.addFilter('role', this.nin_role, '_nin')
      if (this.in_role) this.request.addFilter('role', this.in_role, '_in')
      if (this.role) this.request.addFilter('role', this.role, '_eq')
    },
    setQueries() {
      try {
        const queries = this.$route.query

        if (!queries) return

        if (queries.status) this.request.addFilter('status', queries.status)
      } catch (e) {
        console.log(e)
      }
    },

    getBusiness() {
      this.request_businesses.reset()

      this.request.addFilter('owner_id', this.request.data.id)

      this.request_businesses.setLimit(this.limit)
      this.request_businesses.setSort('date_created')
      this.request_businesses.setFields('id,date_created,name,membership,city,status,owner_id.picture_id,' +
        'owner_id.user_id.email,owner_id.user_id.avatar,owner_id.user_id.phone_number,' +
        'owner_id.user_id.last_access,images.*,phone_number')
      this.request_businesses.get()
    }
  }
}
</script>

<style scoped>

</style>
