<template>
  <div>
    <update-details :id="$route.params.id"></update-details>
  </div>
</template>

<script>
import UpdateDetails from '@/components/updates/UpdateDetails'

export default {
  name: 'UpdatePage',
  components: { UpdateDetails },
  data() {
    return {}
  },
  methods: {}
}
</script>
