import { render, staticRenderFns } from "./CustomerSuggestionFilters.vue?vue&type=template&id=0bff7730&scoped=true&"
import script from "./CustomerSuggestionFilters.vue?vue&type=script&lang=js&"
export * from "./CustomerSuggestionFilters.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bff7730",
  null
  
)

export default component.exports