import { render, staticRenderFns } from "./UpdateSearch.vue?vue&type=template&id=35130c06&scoped=true&"
import script from "./UpdateSearch.vue?vue&type=script&lang=js&"
export * from "./UpdateSearch.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35130c06",
  null
  
)

export default component.exports