<template>
  <v-card class="text-center w-full">
    <v-card-title>
      Edit Operator
    </v-card-title>
    <v-card-text>
      <users-edit :id="id"></users-edit>
    </v-card-text>
  </v-card>
</template>

<script>

import UsersEdit from '@/components/users/UsersEdit'

export default {
  name: 'EditOperator',
  components: { UsersEdit },
  props: {
    id: {
      type: [Number, String],
      default: 0,
      require: true
    }
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {
  },
  methods: {}
}
</script>
