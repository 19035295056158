<template>
  <div class="full-width">
    <div class="font-weight-bold">{{ __('Owner') }}</div>
    <v-select
      :items="items()"
      :value="value"
      class="custom-selector-border rounded-lg"
      outlined
      dense
      @input="(e) => $emit('input', e)"
    ></v-select>
  </div>
</template>

<script>
import { GetRequest } from '@/models/GetRequest'
import { Filters } from '@/models/Filters'

export default {
  name: 'OwnerSelector',
  components: {},
  props: {
    value: {
      default: null,
      type: String
    },
    first_label: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      request: new GetRequest('get_owners')
    }
  },
  mounted() {
    this.get()
  },
  methods: {
    get() {
      if (this.data) {
        this.request.data = this.data

        return
      }

      // this.request.setSort('title')
      // this.request.addFilter('user_id', { status: { _eq: 'active' } }, '_eq')
      // this.request.addFilter('user_id', { confirmation_status: { _eq: 'true' } }, '_eq')
      const filters = [{ 'user_id': { 'status': { '_eq': 'active' } } }, { 'user_id': { 'confirmation_status': { '_eq': 'true' } } }]
      const filter = new Filters()

      filter.default_operation_items = filters
      this.request.setFilter(filter)
      this.request.setFields('*,user_id.*')
      this.request.setLimit(1000)
      this.request.get()
    },
    items() {
      try {
        const items = [
          {
            text: this.first_label ? this.first_label : this.__('All'),
            value: null
          }
        ]

        if (!this.request.data) return
        this.request.data.forEach((item) => {
          items.push({
            text: item.user_id.first_name + ' ' + item.user_id.last_name,
            value: item.id,
            image: item.image
          })
        })

        return items

      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style scoped>

</style>
