<template>
  <div>
    <v-data-table
      v-if="data"
      :headers="headers"

      :items="data"
      :items-per-page="15"
    >
      <template v-slot:item.date_created="{ item }">
        <date-text :data="item.date_created"></date-text>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          icon
          light
          disabled
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import DateText from '@/components/app/DateText'
import AssetsImage from '@/components/assets/Image'

export default {
  name: 'SubscriptionsTable',
  components: { DateText },
  props: {
    data: {
      type: Array,
      require: true
    }
  },
  data() {
    return {
      show_images: false,
      selected_images: null
    }
  },
  computed: {
    headers() {
      const result = [
        {
          text: this.__('FirstName'),
          value: 'user_created.first_name',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('LastName'),
          value: 'user_created.last_name',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('Time'),
          value: 'date_created',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('actions'),
          value: 'actions',
          align: 'start',
          sortable: false
        }
      ]

      return result
    }
  },
  mounted() {
  },
  methods: {
    openImageDialog(images) {
      this.selected_images = images
      this.show_images = !this.show_images
    }
  }
}
</script>
