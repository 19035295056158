<template>
  <div>
    <v-menu v-if="data" offset-y left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="grey "
          icon
          light
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item link :to="{name:'Update',params:{id:data.id}}">
          <v-list-item-title>{{ __("View") }}</v-list-item-title>
        </v-list-item>
        <v-list-item disabled link>
          <v-list-item-title>{{ __("Edit") }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="!hide_archive && data.status !== 'archive'" @click="archive_modal=true">
          <v-list-item-title>{{ __("Archive") }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <archive-modal
      v-model="archive_modal"
      :data="data"
      api_key="archive_update"
      :title="__('ArchiveUpdate')"

      :text="__('AreYouSure')"
      @then="thenArchive"
    />

  </div>
</template>

<script>
import ArchiveModal from '@/components/modal/ArchiveModal'

export default {
  name: 'UpdateActions',
  components: { ArchiveModal },
  props: {
    data: {
      default: null,
      type: Object
    },
    hide_archive: {
      default: false,
      type: Boolean
    }
  },
  data: () => {
    return {
      archive_modal: false
    }
  },

  computed: {},
  methods: {
    thenArchive(e) {
      this.archive_modal = false
      this.$emit('archived', e)
    }
  }
}
</script>

<style scoped>

</style>
