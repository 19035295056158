<template>
  <v-card class="text-center w-full">
    <v-card-title>
      Business Info
    </v-card-title>
    <not-found-data v-if="!request.data"></not-found-data>
    <v-card-text v-else>
      <v-row>
        <v-col cols="12">
          <business :data="request.data"></business>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <business-lists :id="id" :request="request"></business-lists>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

import { GetRequest } from '@/models/GetRequest'
import Business from '@/components/businesses/Business'
import BusinessLists from '@/components/businesses/BusinessLists'
import NotFoundData from '@/components/app/NotFoundData'

export default {
  name: 'BusinessDetails',
  components: { NotFoundData, BusinessLists, Business },
  props: {
    id: {
      default: '',
      type: String,
      require: true
    }
  },
  data() {
    return {
      request: new GetRequest('get_business', [this.id])
    }
  },
  computed: {},
  mounted() {
    this.get()
  },
  methods: {

    get() {
      this.request.reset()
      this.request.setParam('deep',
        '{"updates": {"_limit": 5, "_page": 1}, "coupons": {"_limit": 5, "_page": 1}, ' +
        '"limited_items": {"_limit": 5, "_page": 1}, "chats": {"_limit": 5, "_page": 1}}'
      )

      this.request.setFields('*,owner_id.picture_id,owner_id.user_id.*,owner_id.user_id.role.name,' +
        'category.title,images.id,images.directus_files_id,proof_of_ownership,updates.*,coupons.*,limited_items.*,' +
        'chats.*,chats.customer_id.user_id.first_name,chats.customer_id.user_id.last_name,' +
        'chats.customer_id.user_id.email,chats.messages.*,business_managers.user_id.*,' +
        'business_managers.user_id.role.name')
      this.request.row()
    }
  }
}
</script>

<style scoped>

</style>
