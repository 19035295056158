<template>
  <div>
    <user-details :id="$route.params.id"></user-details>
  </div>
</template>

<script>
import UserDetails from '@/components/users/UserDetails'

export default {
  name: 'UserPage',
  components: { UserDetails },
  data() {
    return {}
  },
  methods: {}
}
</script>
