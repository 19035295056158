<template>
  <div class="d-sm-flex">

    <template v-if="with_expiration_date">
      <date-range-selector
        :title="__('Expiration')"
        :value="value_expiration_date"
        @input="(e) => change(e, 'expiration_date')"
      />
      <space/>
    </template>

    <template v-if="with_business">
      <business-selector :value="getDefault('business_id')" @input="(e) => change(e, 'business_id')"/>
      <space/>
    </template>

    <template v-if="with_status">
      <status-selector v-model="status" :items="status_items" @input="(e) => change(e, 'status')"/>
      <space/>
    </template>

    <template v-if="with_how_often">
      <status-selector
        :title="__('HowOften')"
        :value="getDefault('recurrence_type')"
        :items="how_often_items"
        @input="(e) => change(e, 'recurrence_type')"
      />
      <space/>
    </template>

    <div class="pt-6">
      <v-btn class="border-light rounded-lg" large outlined @click="reset">
        {{ __('Reset') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import StatusSelector from '@/components/selectors/StatusSelector'
import Space from '@/components/app/Space'
import DateRangeSelector from '@/components/selectors/DateRangeSelector'
import { GetRequest } from '@/models/GetRequest'
import BusinessSelector from '@/components/selectors/BusinessSelector'

export default {
  name: 'BusinessCouponFilters',
  components: {
    BusinessSelector, DateRangeSelector, Space, StatusSelector
  },
  props: {
    request: {
      type: GetRequest,
      default: new GetRequest()
    },
    with_status: {
      default: false,
      type: Boolean
    },
    with_expiration_date: {
      default: false,
      type: Boolean
    },
    with_how_often: {
      default: false,
      type: Boolean
    },
    with_business: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      filter: {},
      status_items: [
        {
          text: this.__('All'),
          value: null
        },
        {
          text: this.__('Active'),
          value: 'active'
        },
        {
          text: this.__('Expired'),
          value: 'expired'
        }
      ],
      how_often_items: [
        {
          text: this.__('All'),
          value: null
        },
        {
          text: this.__('Once'),
          value: 'once'
        },
        {
          text: this.__('EveryWeek'),
          value: 'every_week'
        },
        {
          text: this.__('EveryTwoWeeks'),
          value: 'every_two_week'
        },
        {
          text: this.__('EveryMonth'),
          value: 'every_month'
        }
      ],
      value_expiration_date: null,
      status: null
    }
  },
  mounted() {
  },
  methods: {
    reset() {
      this.status = null
      this.value_expiration_date = null
      this.request.setThen()
      this.$emit('reset')
    },
    change(value, key) {
      try {
        let operator = '_eq'

        if (key === 'expiration_date') {
          operator = '_between'
          this.request.setThen(() => this.value_expiration_date = this.getDefault('expiration_date'))
          this.status = null
        }

        if (key === 'status') {
          key = 'expiration_date'
          if (value === 'active') {
            value = '$NOW'
            operator = '_gte'
          } else if (value === 'expired') {
            value = '$NOW'
            operator = '_lt'
          }
          this.request.setThen(() => this.value_expiration_date = null)
        }

        if (!value) this.request.removeFilter(key)
        else this.request.addFilter(key, value, operator)
        this.request.reload()
      } catch (e) {
        console.log(e)
      }
    },
    getDefault(key) {
      try {
        return this.request.getFilter(key)
      } catch (e) {
        return null
      }
    }
  }
}
</script>

<style scoped>

</style>
