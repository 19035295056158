<template>
  <div>
    <limited-details :id="$route.params.id"></limited-details>
  </div>
</template>

<script>
import LimitedDetails from '@/components/limiteds/LimitedDetails'

export default {
  name: 'LimitedPage',
  components: { LimitedDetails },
  data() {
    return {}
  },
  methods: {}
}
</script>
