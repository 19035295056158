<template>
  <div>
    <v-menu
      v-if="user"
      v-model="menu"
      max-width="360"
      offset-x
      left
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <avatar :data="user" color="secondary" v-bind="attrs" v-on="on"/>
        </v-btn>
      </template>

      <v-card class="rounded-lg">
        <user role/>

        <v-divider></v-divider>

        <v-list>

          <v-list-item @click="show_details = !show_details">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ __('myProfile') }}</v-list-item-title>
          </v-list-item>

        </v-list>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <logout/>
        </v-card-actions>
      </v-card>
    </v-menu>
    <slot v-else name="gust"></slot>

    <modal max_width="500" :value="show_details">
      <template v-slot:main>
        <account-details></account-details>
        <v-btn @click="show_details = false">{{ __('close') }}</v-btn>

      </template>
    </modal>
  </div>
</template>

<script>
import Avatar from '@/components/account/Avatar'
import Logout from '@/components/auth/Logout'
import User from '@/components/users/User'
import AccountDetails from '@/components/account/AccountDetails'
import Modal from '@/components/modal/Modal'

export default {
  name: 'Account',
  components: { Modal, AccountDetails, User, Logout, Avatar },
  data() {
    return {
      menu: null,
      show_details: false
    }
  },

  computed: {
    user() {
      return this.$store.state.user_info
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>
