import Vue from 'vue'
import { _t } from '@/tools/Utils'
import app from '@/tools/App'

Vue.mixin({
  computed: {
    _user() {
      try {
        return this.$store.state.user_info
      } catch (e) {
        return false
      }
    }
  },
  methods: {
    __(key, lang = '') {
      return _t(key, lang)
    },
    _develop() {
      try {
        return window.location.hostname === 'localhost'
      } catch (e) {
        return false
      }
    },
    _access(role_key) {
      try {
        if (!role_key) return true
        let { role } = this.$store.state.user_info

        if (typeof role === 'object') role = role.id

        return role === app.roles[role_key]
      } catch (e) {
        return false
      }
    },
    _owner(user_id) {
      try {
        if (!user_id) return false
        if (typeof user_id === 'object') user_id = user_id.id
        const { id } = this.$store.state.user_info

        return id === user_id
      } catch (e) {
        return false
      }
    },
    _role(key) {
      try {
        return app.roles[key]
      } catch (e) {
        return null
      }
    }
  }
})
