<template>
  <v-card class="w-full">
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-text>
      Lorem ipsum dolor sit amet consectetur adipiscing elit prae.
    </v-card-text>
    <v-card-actions>
      <date-text :data="_user.last_access"></date-text>
    </v-card-actions>
  </v-card>
</template>

<script>

import DateText from '@/components/app/DateText'

export default {
  name: 'Welcome',
  components: { DateText },
  props: {},
  data() {
    return {}
  },
  computed: {
    title() {
      return this.__('Welcome') + ' ' + this._user.first_name + ' ' + this._user.last_name
    }
  },
  mounted() {
  },
  methods: {}
}
</script>
