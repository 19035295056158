<template>
  <v-card class="text-center w-full error-page pa-3">
    <v-img :src="img" max-height="400" contain/>
    <div class="display-2 mt-10">{{ title }}</div>
    <div class="mt-3 mb-6">{{ message }}</div>
    <v-btn block large color="primary" @click="$router.go(-1)">{{ __('Send me back') }}</v-btn>
  </v-card>
</template>

<script>

export default {
  name: 'ErrorCard',
  components: {},
  props: {
    title: {
      type: String,
      default: '',
      require: true
    },
    img: {
      type: String,
      default: '',
      require: true
    },
    message: {
      type: String,
      default: '',
      require: true
    }
  },
  data() {
    return {}
  },

  mounted() {
  },
  methods: {}
}
</script>
