<template>
  <input
    v-model="request.search_keyword"
    :placeholder="placeholder ? placeholder : __('Search')"
    class="px-4 py-2 rounded-lg border-light width-full"
    style="max-width: 350px"
    @input="changeInput($event.target.value)"
    @keyup.enter="search"
  />
</template>

<script>
import { GetRequest } from '@/models/GetRequest'
import { Parameters } from '@/models/Parameters'

export default {
  name: 'UsersSearch',
  props: {
    request: {
      type: GetRequest,
      default: new GetRequest(),
      required: true
    },
    placeholder: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      parameters: new Parameters()

    }
  },
  methods: {
    changeInput(value) {
      this.parameters.set('search', value)
      if (value.length > 3) this.search()
    },
    search() {
      this.request.setParameters(this.parameters)
      this.request.get()
    }
  }
}
</script>

<style scoped>

</style>
