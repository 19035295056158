<template>
  <v-card class="text-center w-full">
    <v-card-title>
      Subscription list
    </v-card-title>
    <v-card-text>

      <v-row v-if="request.data">

        <v-row v-if="!hide_actions">
          <v-col class="d-flex justify-space-between">
            <div class="d-flex width-full">

              <!-- Search -->
              <subscription-search :fields="fields" :request="request"></subscription-search>
            </div>

            <space/>

            <div class="d-flex">
              <!-- Limit -->
              <items-limit :request="request"/>
            </div>

            <!--   Filters button     -->
            <v-btn
              v-if="!hide_filters"
              class="rounded-lg border-light"
              outlined
              large
              @click="show_filters = !show_filters"
            >
              <v-icon left>mdi-filter-variant</v-icon>
              {{ __("Filters") }}
            </v-btn>
          </v-col>
        </v-row>

        <!-- Filters -->
        <v-row v-show="show_filters" v-if="!hide_filters">
          <v-col>
            <subscription-filters
              :request="request"
              with_status
              with_date_created
              @reset="get"
            >
            </subscription-filters>
          </v-col>
        </v-row>

        <!-- Loading -->
        <loading v-if="request.isLoading()" :count="loader_count"/>

        <subscriptions-table :data="request.data"></subscriptions-table>
      </v-row></v-card-text>
  </v-card>
</template>

<script>

import Loading from '@/components/app/Loading'
import { GetRequest } from '@/models/GetRequest'
import Space from '@/components/app/Space'
import ItemsLimit from '@/components/items/ItemsLimit'
import SubscriptionFilters from '@/components/subscriptions/SubscriptionFilters'
import SubscriptionSearch from '@/components/subscriptions/SubscriptionSearch'
import SubscriptionsTable from '@/components/subscriptions/SubscriptionsTable'
import NotFoundData from '@/components/app/NotFoundData'

export default {
  name: 'Subscriptions',
  components: { NotFoundData, SubscriptionsTable, SubscriptionSearch, SubscriptionFilters, ItemsLimit, Space, Loading },
  props: {
    loader_count: {
      default: 12,
      type: [String, Number]
    },
    add_is_visible: {
      default: false,
      type: Boolean
    },
    hide_actions: {
      default: false,
      type: Boolean
    },
    hide_filters: {
      default: false,
      type: Boolean
    },
    filter_status: {
      default: true,
      type: Boolean
    },
    limit: {
      default: 5,
      type: [String, Number]
    },
    sort: {
      default: 'date_created',
      type: String
    },
    fields: {
      default: '*,user_created.first_name,user_created.last_name,user_created.email',
      type: String
    }
  },
  data() {
    return {
      request: new GetRequest('get_subscriptions'),
      show_filters: false,
      status: null
    }
  },
  computed: {},
  mounted() {
    this.request.setParam('meta', 'filter_count')
    this.get()
  },
  methods: {

    get() {
      this.request.reset()

      this.setFilters()
      this.setQueries()

      this.request.setLimit(this.limit)
      this.request.setSort(this.sort)
      this.request.setFields(this.fields)
      this.request.get()
    },
    setFilters() {
      if (this.status) this.request.addFilter('status', this.status)
    },
    setQueries() {
      try {
        const queries = this.$route.query

        if (!queries) return

        if (queries.status) this.request.addFilter('status', queries.status)
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
