<template>
  <input
    v-model="search_text"
    :placeholder="placeholder ? placeholder : __('Search')"
    class="px-4 py-2 rounded-lg border-light width-full"
    style="max-width: 350px"
    @input="changeInput($event.target.value)"
    @keyup.enter="search"
  />
</template>

<script>
import { GetRequest } from '@/models/GetRequest'
import { Parameters } from '@/models/Parameters'
import { Filters } from '@/models/Filters'

export default {
  name: 'FeedbackSearch',
  props: {
    request: {
      type: GetRequest,
      default: new GetRequest(),
      required: true
    },
    placeholder: {
      default: null,
      type: String
    },
    fields: {
      default: '*,user_created.first_name,user_created.last_name,user_created.email',
      type: String
    }
  },
  data() {
    return {
      search_text: null
    }
  },
  methods: {
    changeInput(value) {
      if (value.length > 3) this.search()
    },
    search() {
      if (!this.search_text) return false
      if (this.search_text.length < 1) return this.request.reset()
      this.setFilters()
      this.request.setFields(this.fields)

      return this.request.get()
    },
    setFilters() {

      const filters = {
        '_and': [{
          '_or': [{ 'user_created': { 'first_name': { '_contains': this.search_text } } },
            { 'user_created': { 'last_name': { '_contains': this.search_text } } }]
        }]
      }

      const filter = new Filters()

      filter.default_operation_items = filters
      this.request.setFilter(filter)
    }
  }
}
</script>

<style scoped>

</style>
