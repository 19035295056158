<template>
  <div class="text-center w-full">
    <v-tabs>
      <v-tab @click="getCoupons">{{ __('Coupons') }}</v-tab>
      <v-tab @click="getLimited">{{ __('LimitedItem') }}</v-tab>
      <v-tab @click="getSuggestion">{{ __('SuggestionBox') }}</v-tab>
    </v-tabs>
    <!-- Loading -->
    <loading v-if="request && request.isLoading()" :count="loader_count"/>

    <coupons
      v-if="selected_list === 'customer_coupons'"
      :id="id"
      api_key="get_customer_coupons"
      usage="customer"
      :fields="coupon_fields"
    ></coupons>
    <limiteds
      v-if="selected_list === 'customer_limited'"
      :id="id"
      api_key="get_customer_limited"
      usage="customer"
      :fields="limited_fields"
    ></limiteds>
    <suggestions
      v-if="selected_list === 'customer_suggestion'"
      :id="id"
      usage="customer"
      :fields="suggestion_fields"
    ></suggestions>
  </div>
</template>

<script>

import Loading from '@/components/app/Loading'
import { GetRequest } from '@/models/GetRequest'
import Coupons from '@/components/coupons/Coupons'
import Limiteds from '@/components/limiteds/Limiteds'
import Suggestions from '@/components/suggestions/Suggestions'

export default {
  name: 'CustomerLists',
  components: {
    Suggestions,
    Limiteds,
    Coupons,
    Loading
  },
  props: {
    request: {
      type: GetRequest,
      default: new GetRequest()
    },
    id: {
      default: 12,
      type: [String, Number]
    },
    loader_count: {
      default: 12,
      type: [String, Number]
    }
  },
  data() {
    return {
      selected_list: '',
      fields: '*',
      sort: 'date_created',
      limit: null,
      coupon_fields: 'id,number,coupon_id.title,coupon_id.expiration_date,coupon_id.recurrence_type,coupon_id.business_id.name',
      limited_fields: '*,item_id.title,item_id.expiration_date,item_id.business_id.name',
      suggestion_fields: '*,messages.*,business_id.name,messages'
    }
  },
  computed: {},
  mounted() {
    this.getCoupons()
  },
  methods: {
    getCoupons() {
      this.selected_list = 'customer_coupons'
    },
    getLimited() {
      this.selected_list = 'customer_limited'
    },
    getSuggestion() {
      this.selected_list = 'customer_suggestion'
    }
  }
}
</script>
