<template>
  <div v-if="data">
    <!--  Slider  -->
    <v-carousel
      v-if="carousel"
      :height="height"
      cycle
      class="rounded-lg"
      hide-delimiter-background
      :hide-delimiters="hide_delimiters"
      :next-icon="hide_delimiters ? '' : 'chevron-right'"
      :prev-icon="hide_delimiters ? '': 'chevron-left'"
    >
      <v-carousel-item
        v-for="(item, i) in data"
        :key="getUrl(item)+i"
        :src="getUrl(item)"
      >
      </v-carousel-item>
    </v-carousel>

    <!--  Default Images  -->
    <div v-else class="d-flex flex-wrap">
      <v-img
        v-for="(item, index) in data"
        :key="index"
        :max-height="height"
        :max-width="width"
        :src="getUrl(item)"
        class="rounded-lg ma-1 secondary"
        v-bind="$attrs"
      >
        <slot name="image" :item="item" :index="index"></slot>
      </v-img>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import app from '@/tools/App'
import urls from '@/tools/Urls'

export default {
  name: 'AssetsImages',
  inheritAttrs: false,
  props: {
    data: {
      default: null,
      type: Array
    },
    width: {
      default: null,
      type: [Number, String]
    },
    height: {
      default: null,
      type: [Number, String]
    },
    quality: {
      default: 90,
      type: [Number, String]
    },
    type: {
      default: 'cover',
      type: String
    },
    carousel: {
      default: false,
      type: Boolean
    },
    hide_delimiters: {
      default: false,
      type: Boolean
    }

  },
  data() {
    return {
      list: []
    }
  },
  mounted() {

  },
  methods: {

    getUrl(item) {
      try {
        const url = urls.api('assets_url', [item.directus_files_id])

        let params = `?fit=${this.type}`

        if (this.width) params += `&width=${this.width}`
        if (this.height) params += `&height=${this.height}`
        if (this.quality) params += `&quality=${this.quality}`

        return url + params
      } catch (e) {
        return app.default_image
      }
    }
  }
}
</script>

<style scoped>

</style>
