<template>
  <div class="full-width">
    <div class="font-weight-bold">{{ __('Category') }}</div>
    <v-select
      :items="items()"
      :value="value"
      class="custom-selector-border rounded-lg"
      outlined
      dense
      @input="(e) => $emit('input', e)"
    ></v-select>
  </div>
</template>

<script>
import { GetRequest } from '@/models/GetRequest'

export default {
  name: 'CategorySelector',
  components: {},
  props: {
    value: {
      default: null,
      type: String
    },
    first_label: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      request: new GetRequest('get_categories')
    }
  },
  mounted() {
    this.get()
  },
  methods: {
    get() {
      if (this.data) {
        this.request.data = this.data

        return
      }
      this.request.setSort('title')
      this.request.addFilter('*')
      this.request.setLimit(1000)
      this.request.get()
    },
    items() {
      try {
        const items = [
          {
            text: this.first_label ? this.first_label : this.__('All'),
            value: null
          }
        ]

        if (!this.request.data) return
        this.request.data.forEach((item) => {
          items.push({
            text: item.title,
            value: item.id,
            image: item.image
          })
        })

        return items

      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style scoped>

</style>
