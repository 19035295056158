<template>
  <div class="d-sm-flex">

    <template v-if="with_last_message">
      <date-range-selector
        :title="__('LastMessage')"
        :value="value_last_message"
        @input="(e) => change(e, 'last_message_date')"
      />
      <space/>
    </template>

    <template v-if="with_business">
      <business-selector :value="value_business_id" @input="(e) => change(e, 'business_id')"/>
      <space/>
    </template>

    <template v-if="with_message_count">
      <div class="full-width">
        <div class="font-weight-bold">{{ __('MessageCount') }}</div>
        <input
          v-model="message_count"
          :placeholder="__('MessageCount')"
          class="px-4 py-2 rounded-lg border-light width-full"
          @keyup.enter="change(message_count, 'message_count')"
        />
      </div>
    </template>

    <div class="pt-6">
      <v-btn class="border-light rounded-lg" large outlined @click="reset">
        {{ __('Reset') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import Space from '@/components/app/Space'
import DateRangeSelector from '@/components/selectors/DateRangeSelector'
import { GetRequest } from '@/models/GetRequest'
import BusinessSelector from '@/components/selectors/BusinessSelector'

export default {
  name: 'CustomerSuggestionFilters',
  components: {
    BusinessSelector, DateRangeSelector, Space
  },
  props: {
    request: {
      type: GetRequest,
      default: new GetRequest()
    },
    with_last_message: {
      default: false,
      type: Boolean
    },
    with_business: {
      default: false,
      type: Boolean
    },
    with_message_count: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      filter: {},
      value_last_message: null,
      value_business_id: null,
      message_count: null
    }
  },
  mounted() {
  },
  methods: {
    reset() {
      this.value_last_message = null
      this.value_business_id = null
      this.message_count = null
      this.$emit('reset')
    },
    change(value, key) {
      try {
        let operator = '_eq'

        if (key === 'last_message_date') {
          this.value_last_message = value
          operator = '_between'
        }

        if (key === 'business_id') {
          this.value_business_id = value
        }

        if (!value) this.request.removeFilter(key)
        else this.request.addFilter(key, value, operator)
        this.request.reload()
      } catch (e) {
        console.log(e)
      }
    },
    getDefault(key) {
      try {
        return this.request.getFilter(key)
      } catch (e) {
        return null
      }
    }
  }
}
</script>

<style scoped>

</style>
