<template>
  <div>
    <login></login>
  </div>
</template>

<script>
import Login from '@/components/auth/Login'

export default {
  name: 'LoginPage',
  components: { Login },
  data() {
    return {}
  },
  methods: {}
}
</script>
