<template>
  <div>
    <div @click="toggle(true)">
      <slot name="button">
      </slot>
    </div>

    <v-dialog
      v-model="modal"
      :fullscreen="fullscreen"
      :max-width="max_width"
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card :class="{'rounded-lg': !fullscreen}">
        <v-container v-if="fullscreen">
          <v-toolbar color="background" flat>
            <v-btn icon class="grey lighten-4" @click="toggle(false)">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-toolbar-title>
              <slot name="title"></slot>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <slot name="actions">
            </slot>
          </v-toolbar>
        </v-container>

        <v-divider></v-divider>

        <v-container>
          <space v-if="fullscreen" height="15"/>
          <slot name="main"></slot>
          <space v-if="fullscreen" height="90"/>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Space from '../app/Space'

export default {
  name: 'Modal',
  components: { Space },
  props: {
    value: {
      default: null,
      type: Boolean
    },
    fullscreen: {
      default: false,
      type: Boolean
    },
    max_width: {
      default: null,
      type: [String, Number]
    }
  },
  data() {
    return {
      modal: null
    }
  },
  watch: {
    'value'() {
      this.modal = this.value
    }
  },
  mounted() {
  },
  methods: {
    toggle(value) {
      this.modal = value
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped>

</style>
