<template>
  <v-dialog
    fullscreen
    hide-overlay
    persistent
    transition="dialog-bottom-transition"
    v-bind="$attrs"
    @input="e => $emit('input', e)"
  >
    <v-card>
      <v-card-title class="text-h5">
        {{ title }}
        <v-spacer/>

        <v-btn outlined text @click="close">
          {{ __('Cancel') }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <slot></slot>
      </v-card-text>
      <v-card-actions>
        <slot name="actions"></slot>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>
<script>

export default {
  name: 'EditModal',
  components: {},
  props: {
    title: {
      type: String,
      default: '',
      require: true
    }
  },
  data: () => {
    return {}
  },
  mounted() {
  },
  methods: {
    close() {
      this.$emit('input', false)
    }
  }
}
</script>

<style scoped>

</style>
