<template>
  <div class="d-sm-flex">

    <template v-if="with_how_often">
      <status-selector
        :title="__('HowOften')"
        :value="getDefault('recurrence_type')"
        :items="how_often_items"
        @input="(e) => change(e, 'recurrence_type')"
      />
      <space/>
    </template>

    <template v-if="with_status">
      <status-selector v-model="status" :items="status_items" @input="(e) => change(e, 'status')"/>
      <space/>
    </template>

    <div class="pt-6">
      <v-btn class="border-light rounded-lg" large outlined @click="reset">
        {{ __('Reset') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import StatusSelector from '@/components/selectors/StatusSelector'
import Space from '@/components/app/Space'
import { GetRequest } from '@/models/GetRequest'

export default {
  name: 'UpdateFilters',
  components: { Space, StatusSelector },
  props: {
    request: {
      type: GetRequest,
      default: new GetRequest()
    },
    with_status: {
      default: false,
      type: Boolean
    },
    with_how_often: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      filter: {},

      status_items: [
        {
          text: this.__('All'),
          value: null
        },
        {
          text: this.__('Active'),
          value: 'active'
        },
        {
          text: this.__('Pending'),
          value: 'pending'
        },
        {
          text: this.__('Expired'),
          value: 'expired'
        }
      ],
      how_often_items: [
        {
          text: this.__('All'),
          value: null
        },
        {
          text: this.__('Once'),
          value: 'once'
        },
        {
          text: this.__('EveryWeek'),
          value: 'every_week'
        },
        {
          text: this.__('EveryTwoWeeks'),
          value: 'every_two_week'
        },
        {
          text: this.__('EveryMonth'),
          value: 'every_month'
        }
      ],
      status: null
    }
  },
  mounted() {
  },
  methods: {
    reset() {
      this.status = null
      this.$emit('reset')
    },
    change(value, key) {
      try {
        const operator = '_eq'

        if (key === 'status') {
          this.statusFilter(value)
          value = null
        }

        if (!value) this.request.removeFilter(key)
        else this.request.addFilter(key, value, operator)
        this.request.reload()
      } catch (e) {
        console.log(e)
      }
    },
    getDefault(key) {
      try {
        return this.request.getFilter(key)
      } catch (e) {
        return null
      }
    },
    statusFilter(value) {
      if (value === 'active') {
        this.request.addFilter('start_date', '$NOW', '_lte')
        this.request.addFilter('finish_date', '$NOW', '_gte')
      } else if (value === 'active') {
        this.request.addFilter('start_date', '$NOW', '_gt')
      } else if (value === 'expired') {
        this.request.addFilter('finish_date', '$NOW', '_lt')
      } else {
        this.request.removeFilter('start_date')
        this.request.removeFilter('finish_date')
      }
    }
  }
}
</script>

<style scoped>

</style>
