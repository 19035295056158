/**
 * Urls Class
 * this class handle all urls and apis address
 */

const urls = {
  apiParamsSupperKey: '@api_params:',

  urls: {
    base_url: 'https://api.inlo.develop.greensoft.dev',
    // base_url: "https://api.inlo.demo.greensoft.dev",
    generate_site_map: '/generate_site_map?baseUrl=%s',

    get_list: '/items/list', // GET
    get_users: '/users', // GET
    get_user: '/users/%s', // GET
    get_current_user: '/users/me', // GET
    get_items: '/items/%s', // GET
    login_google: '/auth/login/google?redirect=%s', // GET
    assets_url: '/assets/%s', // GET
    get_roles: '/roles', // GET
    get_categories: '/items/category', // GET
    get_owners: '/items/owner', // GET
    get_businesses: '/items/business', // GET
    get_business: '/items/business/%s', // GET Business details
    get_business_updates: '/items/update', // GET
    get_business_update: '/items/update/%s', // GET
    get_business_coupons: '/items/coupon', // GET
    get_business_coupon: '/items/coupon/%s', // GET
    get_customer_coupons: '/items/reserved_coupon', // GET
    get_business_limiteds: '/items/limited_item', // GET
    get_business_limited: '/items/limited_item/%s', // GET
    get_customer_limited: '/items/claimed_item', // GET
    get_business_suggestion: '/items/chat', // GET
    get_customer_suggestion: '/items/chat', // GET
    get_business_manager: '/users', // GET
    get_feedbacks: '/items/feedback', // GET
    get_referrals: '/items/referral', // GET
    get_subscription_message: '/items/message', // GET
    get_customer: '/user/%s', // GET

    login: '/auth/login', // POST
    logout: '/auth/logout', // POST
    // add_portfolio: "/items/idea", // POST
    upload_file: '/files', // POST
    refresh_token: '/auth/refresh', // POST
    add_user: '/users', // GET
    new_business: '/items/business', // GET
    export_referral: '/utils/export/referral', // GET

    update_user: '/users/%s', // PATCH
    update_business: '/items/business/%s', // PATCH
    archive_user: '/users/%s', // PATCH
    archive_business: '/items/business/%s', // PATCH
    archive_referral: '/items/referral/%s', // PATCH
    archive_feedback: '/items/feedback/%s', // PATCH
    archive_limited: '/items/limited_item/%s', // PATCH
    archive_coupon: '/items/coupon/%s', // PATCH
    archive_update: '/items/update/%s', // PATCH
    archive_suggestion: '/items/chat/%s', // PATCH
    update_items: '/items/%s/%s', // PATCH
    update_customer_limited: '/items/claimed_item/%s', // PATCH

    remove_customer_coupon: '/items/reserved_coupon/%s', //DELETE
    remove_customer_limited: '/items/claimed_item/%s' //DELETE

  },

  // Get api
  api(key, parameters = []) {
    return this.urls['base_url'] + this.url(key, parameters)
  },

  // Get url without base url
  url(key, parameters = []) {
    if (!Array.isArray(parameters)) parameters = []
    let url = this.urls[key]

    if (parameters.length !== 0) url = this.set_url_parameters(url, parameters)

    return url
  },

  // Set url parameters
  set_url_parameters(url, params) {
    try {
      params.forEach((param) => {
        url = url.replace('%s', param)
      })

      return url
    } catch (e) {
      console.log()

      return url
    }
  }
}

export default urls
