<template>
  <div>
    <Operators></Operators>
  </div>
</template>

<script>
import Operators from '@/components/operators/Operators'

export default {
  name: 'OperatorsPage',
  components: { Operators },
  data() {
    return {}
  },
  methods: {}
}
</script>
