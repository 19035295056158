<template>
  <v-card flat class="width-full">
    <v-card-text>
      <v-row v-if="request.data">
        <v-col cols="2" offset="3">
          <v-avatar v-if="request.data.avatar === null" size="130" color="secondary">
            <assets-image
              v-if="request.data.avatar && (request.data.avatar.length > 0)"
              :data="request.data.avatar"
              width="64"
            />
            <v-icon v-else color="grey lighten-1" size="64">mdi-account</v-icon>
          </v-avatar>
        </v-col>
        <v-col cols="6">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <full-name :data="request.data"></full-name>
              </v-list-item-title>
              <div>
                {{ request.data.email }}
              </div>

              <div class="mt-5">
                {{ __('phoneNumber') }}:
                {{ request.data.phone_number }}
              </div>

              <div class="mt-5">
                {{ __('Role') }}:<strong v-if="request.data.role && typeof request.data.role === 'object'">{{
                  request.data.role.name
                }}</strong>
              </div>

              <div class="mt-5">
                {{ __('lastSignIn') }}:
                <date-text :data="request.data.last_access"></date-text>
              </div>
              <div class="mt-3">
                <v-btn rounded outlined :href="'mailto:' + request.data.email">{{ __('email') }}</v-btn>
                <v-btn
                  rounded
                  outlined
                  disabled
                  :to="{name:'EditCustomer'}"
                >{{ __('Edit') }}
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="request.data" cols="12">
          <customer-lists :id="request.data.profile_id" :request="request"></customer-lists>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import FullName from '@/components/account/FullName'
import AssetsImage from '@/components/assets/Image'
import DateText from '@/components/app/DateText'
import { GetRequest } from '@/models/GetRequest'
import CustomerLists from '@/components/customers/CustomerLists'

export default {
  name: 'CustomerDetails',
  components: { CustomerLists, DateText, AssetsImage, FullName },
  props: {
    id: {
      default: '',
      type: String,
      require: true
    }
  },
  data() {
    return {
      request: new GetRequest('get_customer', [this.id])
    }
  },
  computed: {},
  mounted() {
    this.get()
  },
  methods: {

    get() {
      this.request.reset()

      this.request.setParam('deep', '' +
        '{"coupons": {"_limit": 5, "_page": 1}, ' +
        '"limited_items": {"_limit": 5, "_page": 1},' +
        ' "chats": {"_limit": 5, "_page": 1}'
      )
      this.request.setFields('id,user_id.*,coupons.coupon_id.business_id.name,coupons.id,' +
        'coupons.coupon_id.title,coupons.coupon_id.expiration_date,coupons.coupon_id.recurrence_type,' +
        'coupons.number,limited_items.*,limited_items.item_id.title,' +
        'limited_items.item_id.business_id.name,chats.*,chats.messages.*,chats.business_id.name')
      this.request.row()
    }

  }
}
</script>

<style scoped>

</style>
