<template>
  <div>
    <v-menu v-if="data" offset-y left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="grey "
          icon
          light
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item v-if="!hide_view" :to="route" link>
          <v-list-item-title>{{ __("View") }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="!hide_edit" link @click="edit">
          <v-list-item-title>{{ __("Edit") }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="!hide_archive && data.status !== 'archive'" @click="archive_modal=true">
          <v-list-item-title>{{ __("Archive") }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <user-archive-modal v-model="archive_modal" :data="data" api_key="archive_user" @then="thenArchive"/>

    <customer-edit
      v-model="customer_edit"
      :data="data"
      :fields="fields"
      @then="thenUpdate"
    >
    </customer-edit>
  </div>
</template>

<script>
import UserArchiveModal from './UserArchiveModal'
import CustomerEdit from '@/components/customers/CustomerEdit'

export default {
  name: 'UserMenu',
  components: { CustomerEdit, UserArchiveModal },
  props: {
    data: {
      default: null,
      type: Object
    },
    hide_archive: {
      default: false,
      type: Boolean
    },
    hide_edit: {
      default: false,
      type: Boolean
    },
    hide_view: {
      default: false,
      type: Boolean
    },
    usage: {
      default: 'operator',
      type: String
    },
    fields: {
      default: '*',
      type: String
    }
  },
  data: () => {
    return {
      archive_modal: false,
      customer_edit: false
    }
  },

  computed: {
    route() {
      if (this.usage === 'customer') {
        return { name: 'Customer', params: { id: this.data.id } }
      } else {
        return { name: 'User', params: { id: this.data.id } }
      }
    }
  },
  methods: {
    edit() {
      if (this.usage === 'customer') {
        this.customer_edit = !this.customer_edit
      } else {
        this.$router.push({ name: 'EditOperator', params: { id: this.data.id } })
      }
    },
    thenUpdate(e) {
      this.customer_edit = false
      this.$emit('then', e)
    },
    thenArchive(e) {
      this.archive_modal = false
      this.data = e
      this.$emit('then', this.data)
    }
  }
}
</script>

<style scoped>

</style>
