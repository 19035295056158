import Vue from 'vue'
import Vuex from 'vuex'
import auth from '../tools/Auth'

const package_info = require('../../package.json')

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    version: package_info.version || '0',
    user_info: {},
    message: {}
  },
  getters: {
    version(state) {
      return state.version
    },
    userInfo(state) {
      return state.user_info
    },
    message(state) {
      return state.message
    }
  },
  mutations: {
    setUserInfo(state, user) {
      state.user_info = user
    },
    setMessage(state, message) {
      state.message = message
    }
  },
  actions: {
    updateUserInfo(context) {
      setTimeout(() => {
        auth.getUserInfo((value) => {
          context.commit('setUserInfo', value)
        })
      }, 150)
    }
  }
})
