<template>
  <div class="d-sm-flex">

    <template v-if="with_date_login">
      <date-range-selector
        :title="__('LastLogin')"
        :value="getDefault('last_access')"
        @input="(e) => change(e, 'last_access')"
      />
      <space/>
    </template>

    <div class="pt-6">
      <v-btn class="border-light rounded-lg" large outlined @click="reset">
        {{ __('Reset') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import StatusSelector from '@/components/selectors/StatusSelector'
import Space from '@/components/app/Space'
import DateRangeSelector from '@/components/selectors/DateRangeSelector'
import { GetRequest } from '@/models/GetRequest'
import BusinessSelector from '@/components/selectors/BusinessSelector'

export default {
  name: 'ManagerFilters',
  components: {
    DateRangeSelector, Space
  },
  props: {
    request: {
      type: GetRequest,
      default: new GetRequest()
    },
    with_date_login: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      filter: {}
    }
  },
  mounted() {
  },
  methods: {
    reset() {
      this.$emit('reset')
    },
    change(value, key) {
      try {
        let operator = '_eq'

        if (key === 'last_access') operator = '_between'

        if (!value) this.request.removeFilter(key)
        else this.request.addFilter(key, value, operator)
        this.request.reload()
      } catch (e) {
        console.log(e)
      }
    },
    getDefault(key) {
      try {
        return this.request.getFilter(key)
      } catch (e) {
        return null
      }
    }
  }
}
</script>

<style scoped>

</style>
