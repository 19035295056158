<template>
  <div>
    <v-menu v-if="data" offset-y left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="grey "
          icon
          light
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list v-if="usage === 'customer'" dense>
          <v-list-item link @click="edit_customer_limited = !edit_customer_limited">
            <v-list-item-title>{{ __("Edit") }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="!hide_archive" @click="remove_modal=true">
            <v-list-item-title>{{ __("Remove") }}</v-list-item-title>
          </v-list-item>
        </v-list>
        <v-list v-else dense>asdf
          <v-list-item link :to="{name:'Limited',params:{id:data.id}}">
            <v-list-item-title>{{ __("View") }}</v-list-item-title>
          </v-list-item>
          <v-list-item disabled link>
            <v-list-item-title>{{ __("Edit") }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="!hide_archive && data.status !== 'archive'" @click="archive_modal=true">
            <v-list-item-title>{{ __("Archive") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-list>
    </v-menu>

    <archive-modal
      v-model="archive_modal"
      :data="data"
      api_key="archive_limited"
      :title="__('ArchiveLimitedItem')"

      :text="__('AreYouSure')"
      @then="thenArchive"
    />

    <remove-modal
      v-model="remove_modal"
      :data="data"
      api_key="remove_customer_limited"
      :title="__('RemoveCoupon')"

      :text="__('AreYouSure')"
      @then="thenRemove"
    />

    <customer-limited-edit
      v-model="edit_customer_limited"
      :data="data"
      :fields="fields"
      @then="thenUpdate"
    >
    </customer-limited-edit>

  </div>
</template>

<script>
import ArchiveModal from '@/components/modal/ArchiveModal'
import RemoveModal from '@/components/modal/RemoveModal'
import CustomerLimitedEdit from '@/components/limiteds/CustomerLimitedEdit'

export default {
  name: 'LimitedActions',
  components: { CustomerLimitedEdit, RemoveModal, ArchiveModal },
  props: {
    data: {
      default: null,
      type: Object
    },
    hide_archive: {
      default: false,
      type: Boolean
    },
    usage: {
      default: 'business',
      type: String
    },
    fields: {
      default: '*',
      type: String
    }
  },
  data: () => {
    return {
      edit_customer_limited: false,
      archive_modal: false,
      remove_modal: false
    }
  },

  computed: {},
  methods: {
    thenUpdate(e) {
      this.edit_customer_limited = false
      this.$emit('then', e)
    },
    thenArchive(e) {
      this.archive_modal = false
      this.$emit('archived', e)
    },
    thenRemove(e) {
      this.remove_modal = false
      this.$emit('archived', e)
    }
  }
}
</script>

<style scoped>

</style>
