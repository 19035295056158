<template>
  <div>
    <v-menu v-if="data" offset-y left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="grey "
          icon
          light
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list v-if="usage === 'customer'" dense>
        <v-list-item link :to="{name:'Coupon',params:{id:data.id}}">
          <v-list-item-title>{{ __("View") }}</v-list-item-title>
        </v-list-item>
        <v-list-item disabled link>
          <v-list-item-title>{{ __("Edit") }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="!hide_archive" @click="remove_modal=true">
          <v-list-item-title>{{ __("Remove") }}</v-list-item-title>
        </v-list-item>
      </v-list>
      <v-list v-else dense>
        <v-list-item link :to="{name:'Coupon',params:{id:data.id}}">
          <v-list-item-title>{{ __("View") }}</v-list-item-title>
        </v-list-item>
        <v-list-item disabled link>
          <v-list-item-title>{{ __("Edit") }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="!hide_archive && data.status !== 'archive'" @click="archive_modal=true">
          <v-list-item-title>{{ __("Archive") }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <archive-modal
      v-model="archive_modal"
      :data="data"
      api_key="archive_coupon"
      :title="__('ArchiveCoupon')"

      :text="__('AreYouSure')"
      @then="thenArchive"
    />

    <remove-modal
      v-model="remove_modal"
      :data="data"
      api_key="remove_customer_coupon"
      :title="__('RemoveCoupon')"

      :text="__('AreYouSure')"
      @then="thenRemove"
    />

  </div>
</template>

<script>
import ArchiveModal from '@/components/modal/ArchiveModal'
import RemoveModal from '@/components/modal/RemoveModal'

export default {
  name: 'CouponActions',
  components: { RemoveModal, ArchiveModal },
  props: {
    data: {
      default: null,
      type: Object
    },
    hide_archive: {
      default: false,
      type: Boolean
    },
    usage: {
      default: 'business',
      type: String
    }
  },
  data: () => {
    return {
      archive_modal: false,
      remove_modal: false
    }
  },

  computed: {},
  methods: {
    thenArchive(e) {
      this.archive_modal = false
      this.$emit('archived', e)
    },
    thenRemove(e) {
      this.remove_modal = false
      this.$emit('archived', e)
    }
  }
}
</script>

<style scoped>

</style>
