<template>
  <div>
    <v-row>
      <v-col cols="7">
        <welcome></welcome>
        <chart class="mt-5"></chart>
      </v-col>
      <v-col cols="5">
        <statistics></statistics>
      </v-col>
    </v-row>
    <v-row>
    </v-row>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import Welcome from '@/components/dashboard/Welcome'
import Statistics from '@/components/dashboard/Statistics'
import Chart from '@/components/dashboard/Chart'

export default {
  name: 'Dashboard',
  components: { Chart, Statistics, Welcome },
  props: {},
  data() {
    return {}
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo
    }
  },
  mounted() {
  },
  methods: {}
}
</script>
