<template>
  <div>
    <customers></customers>
  </div>
</template>

<script>
import Customers from '@/components/customers/Customers'

export default {
  name: 'CustomersPage',
  components: { Customers },
  data() {
    return {}
  },
  methods: {}
}
</script>
