<template>
  <div>
    <limiteds add_is_visible fields="*,business_id.name"></limiteds>
  </div>
</template>

<script>
import Limiteds from '@/components/limiteds/Limiteds'

export default {
  name: 'BusinessesPage',
  components: { Limiteds },
  data() {
    return {}
  },
  methods: {}
}
</script>
