<template>
  <v-card class="text-center w-full">
    <v-card-text>
      <v-row v-if="!hide_actions">
        <v-col class="d-flex justify-space-between">
          <div class="d-flex width-full">
            <!-- Search -->
            <manager-search :fields="fields" :request="request"></manager-search>
          </div>

          <space/>

          <!--   Filters button     -->
          <v-btn
            v-if="!hide_filters"
            class="rounded-lg border-light"
            outlined
            large
            @click="show_filters = !show_filters"
          >
            <v-icon left>mdi-filter-variant</v-icon>
            {{ __("Filters") }}
          </v-btn>
        </v-col>
      </v-row>

      <!-- Filters -->
      <v-row v-show="show_filters" v-if="!hide_filters">
        <v-col>
          <manager-filters
            :request="request"
            :with_date_login="false"
            @reset="get"
          >
          </manager-filters>
        </v-col>
      </v-row>

      <!-- Loading -->
      <loading v-if="request.isLoading()" :count="12"/>

      <managers-table :data="managers"></managers-table>
    </v-card-text>
  </v-card>
</template>

<script>

import Loading from '@/components/app/Loading'
import { GetRequest } from '@/models/GetRequest'
import Space from '@/components/app/Space'
import ManagerFilters from '@/components/businesses/ManagerFilters'
import ManagerSearch from '@/components/businesses/ManagerSearch'
import ManagersTable from '@/components/businesses/ManagersTable'

export default {
  name: 'Managers',
  components: { ManagersTable, ManagerSearch, ManagerFilters, Space, Loading },
  props: {
    request: {
      type: GetRequest,
      default: new GetRequest()
    },
    hide_actions: {
      default: false,
      type: Boolean
    },
    hide_filters: {
      default: false,
      type: Boolean
    },
    limit: {
      default: 5,
      type: [String, Number]
    },
    sort: {
      default: 'date_created',
      type: String
    },
    fields: {
      default: '*',
      type: String
    }
  },
  data() {
    return {
      show_filters: false
    }
  },
  computed: {
    managers() {
      const users = []

      users.push(this.request.data.owner_id.user_id)
      this.request.data.business_managers.forEach((u) => users.push(u.user_id))

      return users
    }
  },
  mounted() {
  },
  methods: {

    get() {
      this.request.reset()

      this.setFilters()
      this.setQueries()

      this.request.setLimit(this.limit)
      this.request.setNotIn('id', this.without)
      this.request.setSort(this.sort)
      this.request.setFields(this.fields)
      this.request.get()
    }
  }
}
</script>
