<template>
  <div>
    <not-found-data v-if="!data || (data.length < 1)" style="padding-top: 90px !important;"/>
    <v-data-table
      v-else
      :headers="headers"

      :items="data"
      :items-per-page="15"
    >
      <template v-slot:item.start_date="{ item }">
        <date-text :data="item.start_date"></date-text>
      </template>
      <template v-slot:item.finish_date="{ item }">
        <date-text :data="item.finish_date"></date-text>
      </template>
      <template v-slot:item.is_active="{ item }">
        <span>{{ item.is_active ? __('active') : __('expired') }}</span>
      </template>
      <template v-slot:item.image="{ item }">
        <assets-image width="50" :data="item.image"></assets-image>
      </template>
      <template v-slot:item.actions="{ item }">
        <update-actions :data="item" @then="updateData" @archived="archivedData"></update-actions>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import DateText from '@/components/app/DateText'
import AssetsImage from '@/components/assets/Image'
import NotFoundData from '@/components/app/NotFoundData'
import UpdateActions from '@/components/updates/UpdateActions'

export default {
  name: 'UpdatesTable',
  components: { UpdateActions, NotFoundData, AssetsImage, DateText },
  props: {
    data: {
      type: Array,
      require: true
    }
  },
  data() {
    return {
      show_images: false,
      selected_images: null
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.__('Title'),
          value: 'title',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('HowOften'),
          value: 'recurrence_type',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('StartDate'),
          value: 'start_date',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('FinishDate'),
          value: 'finish_date',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('image'),
          value: 'image',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('status'),
          value: 'is_active',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('actions'),
          value: 'actions',
          align: 'start',
          sortable: false
        }
      ]
    }
  },
  mounted() {
  },
  methods: {
    openImageDialog(images) {
      this.selected_images = images
      this.show_images = !this.show_images
    },
    updateData(result) {
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].hasOwnProperty('id')) {
          if (this.data[i].id === result.id) {
            this.data[i] = result
          }
        }
      }
    },
    archivedData(result) {
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].hasOwnProperty('id')) {
          if (this.data[i].id === result.id) {
            this.data.splice(i, 1)
          }
        }
      }
    }
  }
}
</script>
