<template>
  <div>
    <div @click="toggle(true)">
      <slot name="button">
      </slot>
      <slot></slot>
    </div>

    <v-dialog
      v-model="modal"
      max-width="400px"
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ title ? title : __('AruYouSure') }}
        </v-card-title>
        <v-card-actions>
          <v-btn :color="success_color" text @click="result(false)">
            {{ __('Cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn :color="error_color" text @click="result(true)">
            {{ __('Yes') }}
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: 'Confirm',
  components: {},
  props: {
    value: {
      default: null,
      type: Boolean
    },
    title: {
      default: null,
      type: String
    },
    text: {
      default: null,
      type: String
    },
    success_color: {
      default: 'default',
      type: String
    },
    error_color: {
      default: 'error',
      type: String
    }
  },
  data() {
    return {
      modal: null
    }
  },
  watch: {
    'value'() {
      this.modal = this.value
    }
  },
  mounted() {
  },
  methods: {
    toggle(value) {
      this.modal = value
      this.$emit('input', value)
    },
    result(value) {
      this.toggle(false)
      this.$emit('then', value)
      if (value) this.$emit('accepted', value)
      if (!value) this.$emit('canceled', value)
    }
  }
}
</script>

<style scoped>

</style>
