<template>
  <v-card class="w-full">
    <v-card-title>
      {{ __('Chart') }}
      <v-spacer/>
      <date-selector :disabled="true"/>
    </v-card-title>
    <v-card-text>
      <v-alert elevation="15">
        <strong class="ma-1">{{ __('Today') }}</strong>: 3 {{ __('Items') }}
        <strong class="ma-1">{{ __('This Month') }}</strong>: 12 {{ __('Items') }}
        <strong class="ma-1">{{ __('Total') }}</strong>: 30 {{ __('Items') }}
      </v-alert>
      <v-sparkline
        :labels="labels"
        :value="values"
        color="primary"
        line-width="2"
        padding="16"
      >
      </v-sparkline>
    </v-card-text>
    <v-card-actions>
    </v-card-actions>
  </v-card>
</template>

<script>

import DateSelector from '@/components/selectors/DateSelector'

export default {
  name: 'Chart',
  components: { DateSelector },
  props: {},
  data() {
    return {}
  },
  computed: {
    title() {
      return this.__('Welcome') + ' ' + this._user.first_name + ' ' + this._user.last_name
    },
    labels() {
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
    },
    values() {
      return [0, 50, 500, 780, 100, 200, 0, 900, 500, 70]
    }
  },
  mounted() {
  },
  methods: {}
}
</script>
