<template>
  <div class="full-width">
    <div v-if="!hide_title" class="font-weight-bold">{{ __('Count') }}</div>
    <select
      class="rounded-lg border-light"
      style="padding:10px 10px 10px 10px; min-width: 48px; text-align: center"
      @input="(e) => $emit('input', e.target.value)"
    >
      <option
        v-for="(item, key) in (items ? items : getDefault())"
        :key="'select-count-'+key"
        :value="item.value"
        :selected="value == item.value"
      >
        {{ item.text }}
      </option>
    </select>
  </div>
</template>

<script>

export default {
  name: 'CountSelector',
  props: {
    value: {
      default: null,
      type: [String, Number]
    },
    items: {
      default: null,
      type: [Array, Object]
    },
    hide_title: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    getDefault() {
      return [
        {
          text: 12,
          value: 12
        },
        {
          text: 24,
          value: 24
        },
        {
          text: 48,
          value: 48
        },
        {
          text: 120,
          value: 120
        },
        {
          text: 720,
          value: 720
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
