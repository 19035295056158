import { render, staticRenderFns } from "./ManagerFilters.vue?vue&type=template&id=680b56ab&scoped=true&"
import script from "./ManagerFilters.vue?vue&type=script&lang=js&"
export * from "./ManagerFilters.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "680b56ab",
  null
  
)

export default component.exports