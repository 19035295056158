<template>
  <v-card class="text-center w-full">
    <v-card-title>
      Customers list
    </v-card-title>
    <v-card-text>
      <users
        :role="_role('customer')"
        :add_is_visible="false"
        filter_status
        with_date_login
        with_date_register
        usage="customer"
      />
    </v-card-text>
  </v-card>
</template>

<script>

import Users from '@/components/users/Users'

export default {
  name: 'Customers',
  components: { Users },
  props: {},
  data() {
    return {}
  },
  computed: {},
  mounted() {
  },
  methods: {}
}
</script>
