<template>
  <v-card class="w-full">
    <v-card-title>
      {{ __('Statistics') }}
      <v-spacer/>
      <date-selector :disabled="true"/>
    </v-card-title>
    <v-card-text>
      <v-list>
        <v-list-item v-for="(item,index) in list" :key="item+index">
          <v-list-item-title>
            {{ __(item.text) }}
          </v-list-item-title>
          <v-list-item-action>
            <v-chip small>{{ item.count }}</v-chip>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-actions>
    </v-card-actions>
  </v-card>
</template>

<script>

import DateSelector from '@/components/selectors/DateSelector'

export default {
  name: 'Statistics',
  components: { DateSelector },
  props: {},
  data() {
    return {}
  },
  computed: {
    title() {
      return this.__('Welcome') + ' ' + this._user.first_name + ' ' + this._user.last_name
    },
    list() {
      return [
        {
          text: 'NewFeedback',
          count: 12
        },
        {
          text: 'NotApproveBusiness',
          count: 12
        },
        {
          text: 'AllCoupons',
          count: 12
        },
        {
          text: 'AllLimitedItems',
          count: 12
        },
        {
          text: 'AllUpdates',
          count: 12
        },
        {
          text: 'AllClaims',
          count: 12
        },
        {
          text: 'AllRedeemed',
          count: 12
        }
      ]
    }
  },
  mounted() {
  },
  methods: {}
}
</script>
