import {PostRequest} from "./PostRequest";
import api from "../tools/Api";

export class DeleteRequest extends PostRequest {

    constructor(api, param = null) {
        super(api, param);
    }

    /**
     * Send Delete Request
     */
    delete() {
        if (!this.api_key) return;
        if (this.is_loading) return;
        this.is_loading = true;

        api.delete(this._getUrl(), {data: this.getBody()})
            .then((res) => this._thenRequest(res))
            .finally(() => this._finallyRequest())
    }
}