<template>
  <div class="full-width">
    <div class="font-weight-bold">{{ title ? title : __('DateRange') }}</div>
    <div class="border-light px-2 rounded-lg pointer py-2 px-2 text-truncate" @click="dialog = true">
      <span v-if="start === '-'">{{ __('All') }}</span>
      <span v-else>{{ start }} - {{ end }}</span>
    </div>

    <v-dialog v-model="dialog" persistent max-width="590">
      <v-card>
        <v-card-title class="text-h5">
          {{ __('SelectDateRange') }}
        </v-card-title>
        <div class="pa-6 text-center">
          <v-date-picker
            v-model="date"
            elevation="1"
            :value="value"
            range
          ></v-date-picker>
        </div>
        <v-card-actions class="pb-4">
          <v-btn text @click="dialog = false">
            {{ __('Cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="pick">
            {{ __('Select') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: 'DateRangeSelector',
  components: {},
  props: {
    title: {
      default: null,
      type: String
    },
    value: {
      default: null,
      type: [Array, Object]
    },
    custom: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      date: null,
      dialog: null
    }
  },
  computed: {
    start() {
      try {
        return this.value[0]
      } catch (e) {
        return '-'
      }
    },
    end() {
      try {
        return this.value[1]
      } catch (e) {
        return '-'
      }
    }
  },
  mounted() {

  },
  methods: {
    pick(e) {
      try {
        this.dialog = false
        this.$emit('input', this.date)
      } catch (e) {
        console.log(e)
      }
    }

  }
}
</script>

<style>
</style>
