<template>
  <v-card class="text-center w-full">
    <v-card-title>
      New Operator
    </v-card-title>
    <v-card-text>
      <users-add></users-add>
    </v-card-text>
  </v-card>
</template>

<script>

import UsersAdd from '@/components/users/UsersAdd'

export default {
  name: 'NewOperator',
  components: { UsersAdd },
  props: {},
  data() {
    return {}
  },
  computed: {},
  mounted() {
  },
  methods: {}
}
</script>
