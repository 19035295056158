<template>
  <v-card class="w-full">
    <v-card-title>
      Suggestion Info
    </v-card-title>
    <v-card-text>
      <v-list>
        <v-list-item
          v-for="msg in request.data"
          :key="'msg-'+msg.id"
          three-line
          :class="msg.from ==='customer' ? 'v-application--is-rtl' : 'v-application--is-ltr'"
        >
          <v-list-item-avatar>
            <avatar v-if="msg.sender.avatar" :data="msg.sender.avatar"></avatar>
            <v-avatar
              v-else
              color="primary"
              size="56"
            >
              {{ msg.sender.first_name[0] }}
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle>
              <strong>
                <full-name :data="msg.sender"></full-name>
              </strong>
              <v-list-item-subtitle dir="auto" style="max-width: 100px" class="text-center">
                <date-text :data="msg.date_created"></date-text>
              </v-list-item-subtitle>
            </v-list-item-subtitle>
            {{ msg.text }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>

import { GetRequest } from '@/models/GetRequest'
import DateText from '@/components/app/DateText'
import FullName from '@/components/account/FullName'
import Avatar from '@/components/account/Avatar'

export default {
  name: 'SuggestionDetails',
  components: { Avatar, FullName, DateText },
  props: {
    id: {
      default: '',
      type: String,
      require: true
    },
    data: {
      default: null,
      type: Object
    }
  },
  data() {
    return {
      request: new GetRequest('get_subscription_message', [this.id])
    }
  },
  computed: {},
  mounted() {
    this.get()
  },
  methods: {

    get() {
      this.request.reset()
      this.request.setParam('deep',
        '{"updates": {"_limit": 5, "_page": 1}, "coupons": {"_limit": 5, "_page": 1}, ' +
        '"limited_items": {"_limit": 5, "_page": 1}, "chats": {"_limit": 5, "_page": 1}}'
      )

      this.request.setFields('*,sender.*')
      this.request.setSort('date_created')
      this.request.get()
    }
  }
}
</script>

<style scoped>

</style>
