<template>
  <div>
    <businesses add_is_visible with_date_login with_date_register></businesses>
  </div>
</template>

<script>
import Businesses from '@/components/businesses/Businesses'

export default {
  name: 'BusinessesPage',
  components: { Businesses },
  data() {
    return {}
  },
  methods: {}
}
</script>
