<template>
  <div class="d-sm-flex">

    <template v-if="with_status">
      <status-selector :value="getDefault('status')" :items="status_items" @input="(e) => change(e, 'status')"/>
      <space/>
    </template>

    <div class="pt-6">
      <v-btn class="border-light rounded-lg" large outlined @click="reset">
        {{ __('Reset') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import Space from '@/components/app/Space'
import DateRangeSelector from '@/components/selectors/DateRangeSelector'
import { GetRequest } from '@/models/GetRequest'
import BusinessSelector from '@/components/selectors/BusinessSelector'
import StatusSelector from '@/components/selectors/StatusSelector'

export default {
  name: 'UsedLimitedFilters',
  components: {
    StatusSelector,
    Space
  },
  props: {
    request: {
      type: GetRequest,
      default: new GetRequest()
    },
    with_status: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      filter: {},
      status_items: [
        {
          text: this.__('All'),
          value: null
        },
        {
          text: this.__('Reserved'),
          value: 'reserved'
        },
        {
          text: this.__('Claimed'),
          value: 'claimed'
        },
        {
          text: this.__('Expired'),
          value: 'expired'
        }
      ]
    }
  },
  mounted() {
  },
  methods: {
    reset() {
      this.$emit('reset')
    },
    change(value, key) {
      try {
        const operator = '_eq'

        if (!value) this.request.removeFilter(key)
        else this.request.addFilter(key, value, operator)
        this.request.reload()
      } catch (e) {
        console.log(e)
      }
    },
    getDefault(key) {
      try {
        return this.request.getFilter(key)
      } catch (e) {
        return null
      }
    }
  }
}
</script>

<style scoped>

</style>
