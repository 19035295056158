<template>
  <div>
    <subscriptions add_is_visible></subscriptions>
  </div>
</template>

<script>
import Subscriptions from '@/components/subscriptions/Subscriptions'

export default {
  name: 'SubscriptionsPage',
  components: { Subscriptions },
  data() {
    return {}
  },
  methods: {}
}
</script>
