<template>
  <!--  Add Idea Form  -->
  <form v-if="request.body.first_name !== undefined" @submit.prevent="request.post()">
    <v-row>
      <v-col cols="12" md="6">
        <div class="font-weight-bold">{{ __('FirstName') }} *</div>
        <v-text-field
          v-model="request.body.first_name"
          dense
          outlined
          required
        />
      </v-col>
      <v-col cols="12" md="6">
        <div class="font-weight-bold">{{ __('LastName') }} *</div>
        <v-text-field
          v-model="request.body.last_name"
          dense
          outlined
          required
        />
      </v-col>
      <v-col cols="12" md="6">
        <div class="font-weight-bold">{{ __('Password') }} *</div>
        <v-text-field
          v-model="request.body.password"
          dense
          type="password"
          outlined
          required
        />
      </v-col>
      <v-col cols="12" md="6">
        <div class="font-weight-bold">{{ __('Email') }}</div>
        <v-text-field
          v-model="request.body.email"
          dense
          outlined
        />
      </v-col>

      <v-col v-if="!role" cols="12" md="6">
        <role-selector v-model="request.body.role" :first_label="__('SelectOne')"/>
      </v-col>

      <v-col md="12">

        <v-btn type="submit" color="primary" elevation="0" :disabled="request.isLoading()">
          <span v-if="!request.is_loading">{{ __('Submit') }}</span>
          <span v-else>...</span>
        </v-btn>
      </v-col>

    </v-row>
  </form>
</template>

<script>
import { PostRequest } from '@/models/PostRequest'
import RoleSelector from '@/components/selectors/RoleSelector'

export default {
  name: 'UsersAdd',
  components: { RoleSelector },
  props: {
    role: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      request: new PostRequest('add_user')
    }
  },
  mounted() {
    this.request.setThen(this.submitted)
    this.setBody()
  },
  methods: {
    setBody() {
      this.request.setBody(
        {
          first_name: null,
          last_name: null,
          password: null,
          email: null,
          role: this.role ? this.role : null
        }
      )
    },
    submitted(data) {
      this.$emit('then', data)
      this.setBody()
    }
  }
}
</script>

<style scoped>

</style>
