<template>
  <img src="@/assets/img/logo-text.png" :alt="app_name" :width="width" v-bind="$attrs"/>
</template>

<script>
import app from "../../tools/App";

export default {
  name: "Logo",
  props:{
    width: {
      default: '135',
      type: [String, Number,]
    }
  },
  data() {
    return {
      app_name: app.app_name,
    }
  }
}
</script>

<style scoped>

</style>