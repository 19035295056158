<template>
  <div class="d-sm-flex">

    <template v-if="with_date_created">
      <date-range-selector
        :title="__('SentDate')"
        :value="getDefault('date_created')"
        @input="(e) => change(e, 'date_created')"
      />
      <space/>
    </template>

    <template v-if="with_status">
      <status-selector :value="getDefault('status')" :items="status_items" @input="(e) => change(e, 'status')"/>
      <space/>
    </template>

    <div class="pt-6">
      <v-btn class="border-light rounded-lg" large outlined @click="reset">
        {{ __('Reset') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import StatusSelector from '@/components/selectors/StatusSelector'
import Space from '@/components/app/Space'
import DateRangeSelector from '@/components/selectors/DateRangeSelector'
import { GetRequest } from '@/models/GetRequest'

export default {
  name: 'FeedbackFilters',
  components: {
    DateRangeSelector, Space, StatusSelector
  },
  props: {
    request: {
      type: GetRequest,
      default: new GetRequest()
    },
    with_status: {
      default: false,
      type: Boolean
    },
    with_date_created: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      filter: {},
      status_items: [
        {
          text: this.__('All'),
          value: null
        },
        {
          text: this.__('Published'),
          value: 'published'
        },
        {
          text: this.__('Archive'),
          value: 'archived'
        }
      ]
    }
  },
  mounted() {
  },
  methods: {
    reset() {
      this.$emit('reset')
    },
    change(value, key) {
      try {
        let operator = '_eq'

        if (key === 'date_created') {
          operator = '_between'
        }

        if (!value) this.request.removeFilter(key)
        else this.request.addFilter(key, value, operator)
        this.request.reload()
      } catch (e) {
        console.log(e)
      }
    },
    getDefault(key) {
      try {
        return this.request.getFilter(key)
      } catch (e) {
        return null
      }
    }
  }
}
</script>

<style scoped>

</style>
