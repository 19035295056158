<template>
  <div>
    <not-found-data v-if="!data || (data.length < 1)" style="padding-top: 90px !important;"/>
    <v-data-table
      v-else
      :headers="headers"
      :items="data"
      :items-per-page="15"
    >
      <template v-slot:item.last_access="{ item }">
        <date-text :data="item.last_access"></date-text>
      </template>
      <template v-slot:item.email="{ item }">
        <v-btn
          x-small
          link
          text
          :href="'mailto:'+item.email"
          target="_blank"
        >
          {{ item.email }}
        </v-btn>
      </template>
      <template v-slot:item.avatar="{ item }">
        <assets-image
          v-if="item.avatar"
          width="50"
          :data="item.avatar"
        ></assets-image>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import DateText from '@/components/app/DateText'
import NotFoundData from '@/components/app/NotFoundData'
import AssetsImage from '@/components/assets/Image'

export default {
  name: 'ManagersTable',
  components: { AssetsImage, NotFoundData, DateText },
  props: {
    data: {
      type: Array,
      require: true
    },
    with_business_name: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    headers() {
      const result = [
        {
          text: this.__('FirstName'),
          value: 'first_name',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('LastName'),
          value: 'last_name',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('Email'),
          value: 'email',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('Role'),
          value: 'role.name',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('Image'),
          value: 'avatar',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('LastSignIn'),
          value: 'last_access',
          align: 'start',
          sortable: false
        }
      ]

      return result
    }
  },
  mounted() {
  },
  methods: {
    updateData(result) {
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].hasOwnProperty('id')) {
          if (this.data[i].id === result.id) {
            this.data[i] = result
          }
        }
      }
    },
    archivedData(result) {
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].hasOwnProperty('id')) {
          if (this.data[i].id === result.id) {
            this.data.splice(i, 1)
          }
        }
      }
    }
  }
}
</script>
