<template>
  <v-card class="w-full">
    <v-card-title>
      Update Info
    </v-card-title>
    <not-found-data v-if="!request.data"></not-found-data>
    <v-card-text v-if="request.data">
      <v-row>
        <v-col cols="3">
          <assets-image :data="request.data.image">
            <v-btn icon class="primary" small disabled>
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </assets-image>
        </v-col>
        <v-col cols="9">

          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title>
                {{ request.data.title }}
                <v-list-item-subtitle>
                  <strong>{{ __('Category') }}</strong>: {{ request.data.business_id.category.title }}
                </v-list-item-subtitle>
              </v-list-item-title>
              <div class="mt-3">
                <strong>{{ __('Content') }}</strong>:{{ request.data.content }}
              </div>
              <div class="mt-3">
                <strong>{{ __('Recurrence') }}</strong>: {{ request.data.recurrence_type }}
              </div>
              <div class="mt-3">
                <strong>{{ __('StartDate') }}</strong>:
                <date-text :data="request.data.start_date"></date-text>
              </div>
              <div class="mt-3">
                <strong>{{ __('FinishDate') }}</strong>:
                <date-text :data="request.data.finish_date"></date-text>
              </div>
            </v-list-item-content>
            <v-list-item-action-text>
              <v-chip small :color="request.data.is_active ? 'success' : 'error'">
                <v-icon v-if="request.data.is_active">mdi-check</v-icon>
                <v-icon v-else>mdi-close</v-icon>
              </v-chip>
            </v-list-item-action-text>
          </v-list-item>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions v-if="request.data">
      <v-btn disabled>{{ __('UploadPicture') }}</v-btn>
      <v-btn disabled>{{ __('Edit') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import { GetRequest } from '@/models/GetRequest'
import AssetsImage from '@/components/assets/Image'
import DateText from '@/components/app/DateText'
import NotFoundData from '@/components/app/NotFoundData'

export default {
  name: 'UpdateDetails',
  components: { NotFoundData, DateText, AssetsImage },
  props: {
    id: {
      default: '',
      type: String,
      require: true
    }
  },
  data() {
    return {
      request: new GetRequest('get_business_update', [this.id])
    }
  },
  computed: {},
  mounted() {
    this.get()
  },
  methods: {

    get() {
      this.request.reset()
      this.request.setFields('*,business_id.category.title')
      this.request.row()
    }
  }
}
</script>

<style scoped>

</style>
