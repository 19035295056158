import { render, staticRenderFns } from "./BusinessForm.vue?vue&type=template&id=60bb8294&scoped=true&"
import script from "./BusinessForm.vue?vue&type=script&lang=js&"
export * from "./BusinessForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60bb8294",
  null
  
)

export default component.exports