<template>
  <ErrorCard
    img="/images/illustrations/404-illustration.svg"
    :title="__('How did you get here?')"
    :message="__(`Sorry we can't seem to find the page you're looking for.`)"
  />
</template>

<style>
.error-page {
  max-width: 500px;
}
</style>
<script>
import ErrorCard from '@/components/errors/ErrorCard'

export default {
  components: { ErrorCard }
}
</script>
