<template>
  <!--  Add Idea Form  -->
  <div>
    <loading v-if="request_get.isLoading()"/>

    <form v-else-if="request.body.first_name !== undefined" @submit.prevent="submit()">
      <v-row>
        <v-col cols="12" md="6">
          <div class="font-weight-bold">{{ __('FirstName') }} *</div>
          <v-text-field
            v-model="request.body.first_name"
            dense
            outlined
            required
          />
        </v-col>
        <v-col cols="12" md="6">
          <div class="font-weight-bold">{{ __('LastName') }} *</div>
          <v-text-field
            v-model="request.body.last_name"
            dense
            outlined
            required
          />
        </v-col>
        <v-col cols="12" md="6">
          <div class="font-weight-bold">{{ __('Email') }}</div>
          <v-text-field
            v-model="request.body.email"
            dense
            outlined
          />
        </v-col>

        <v-col v-if="with_phone_number" cols="12" md="6">
          <div class="font-weight-bold">{{ __('PhoneNumber') }}</div>
          <v-text-field
            v-model="request.body.phone_number"
            dense
            outlined
          />
        </v-col>

        <v-col v-if="with_password" cols="12" md="6">
          <div class="font-weight-bold">{{ __('Password') }}</div>
          <v-text-field
            v-model="request.body.password"
            dense
            outlined
          />
        </v-col>

        <v-col v-if="with_role" cols="12" md="6">
          <role-selector v-model="request.body.role"/>
        </v-col>

        <v-col v-if="with_status" cols="12" md="6">
          <status-selector v-model="request.body.status" :items="status_items"/>
        </v-col>

        <v-col md="12">
          <v-btn type="submit" color="primary" elevation="0" :disabled="request.isLoading()">
            <span v-if="!request.is_loading">{{ __('Submit') }}</span>
            <span v-else>...</span>
          </v-btn>
        </v-col>

      </v-row>
    </form>
  </div>
</template>

<script>
import { PostRequest } from '@/models/PostRequest'
import RoleSelector from '@/components/selectors/RoleSelector'
import StatusSelector from '@/components/selectors/StatusSelector'
import { GetRequest } from '@/models/GetRequest'
import Loading from '@/components/app/Loading'

export default {
  name: 'UsersEdit',
  components: { Loading, StatusSelector, RoleSelector },
  props: {
    id: {
      type: [Number, String],
      default: 0,
      require: true
    },
    with_phone_number: {
      type: Boolean,
      default: false
    },
    with_password: {
      type: Boolean,
      default: false
    },
    with_role: {
      type: Boolean,
      default: false
    },
    with_status: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      request_get: new GetRequest('get_user', [this.id]),
      request: new PostRequest('update_user', [this.id]),
      status_items: [
        {
          text: this.__('Active'),
          value: 'active'
        },
        {
          text: this.__('Archive'),
          value: 'archived'
        }
      ]
    }
  },
  mounted() {
    this.get()
  },
  methods: {
    get() {
      this.request_get.setThen(this.setBody)
      this.request_get.setFields('*, role.*')
      this.request_get.row()
    },
    setBody() {
      this.request.setThen(this.submitted)
      const body = {
        first_name: this.request_get.data.first_name,
        last_name: this.request_get.data.last_name,
        email: this.request_get.data.email
      }

      if (this.with_password) {
        body.password = null
      }

      if (this.with_phone_number) {
        body.with_phone_number = this.request_get.data.with_phone_number
      }

      if (this.with_role) {
        body.with_role = this.request_get.data.with_role
      }

      if (this.with_status) {
        body.with_status = this.request_get.data.with_status
      }

      this.request.setBody(body)
    },
    submit() {
      this.request.patch()
    },
    submitted(data) {
      this.$emit('then', data.data)
      this.get()
    }
  }
}
</script>

<style scoped>

</style>
