<template>
  <div>
    <feedbacks add_is_visible></feedbacks>
  </div>
</template>

<script>
import Feedbacks from '@/components/feedbacks/Feedbacks'

export default {
  name: 'FeedbacksPage',
  components: { Feedbacks },
  data() {
    return {}
  },
  methods: {}
}
</script>
