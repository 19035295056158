<template>
  <v-card class="text-center w-full">
    <v-card-title v-if="!id">
      Coupon list
    </v-card-title>
    <v-card-text>
      <v-row v-if="!hide_actions">
        <v-col class="d-flex justify-space-between">
          <div class="d-flex width-full">

            <!-- Add Button   -->
            <template v-if="add_is_visible">
              <v-btn
                disabled
                color="primary"
                elevation="0"
                large
                class="hidden-sm-and-down rounded-lg"
                :to="{name:'NewCoupon'}"
              >
                {{ __('createCoupon') }}
              </v-btn>
              <v-btn
                disabled
                color="primary"
                fab
                elevation="0"
                small
                class="hidden-md-and-up"
                :to="{name:'NewCoupon'}"
                :title="__('createCoupon')"
              >
                <v-icon dark>mdi-plus</v-icon>
              </v-btn>
            </template>

            <space/>

            <!-- Search -->
            <coupon-search :id="id" :fields="fields" :request="request" :usage="usage"></coupon-search>
          </div>

          <space/>

          <div class="d-flex">
            <!-- Limit -->
            <items-limit :request="request"/>
          </div>

          <!--   Filters button     -->
          <v-btn
            v-if="!hide_filters"
            class="rounded-lg border-light"
            outlined
            large
            @click="show_filters = !show_filters"
          >
            <v-icon left>mdi-filter-variant</v-icon>
            {{ __("Filters") }}
          </v-btn>
        </v-col>
      </v-row>

      <!-- Filters -->
      <v-row v-show="show_filters" v-if="!hide_filters">
        <v-col>
          <customer-coupon-filters
            v-if="usage === 'customer'"
            :request="request"
            with_expiration_date
            with_business
            @reset="get"
          >
          </customer-coupon-filters>
          <business-coupon-filters
            v-else
            :request="request"
            :with_status="filter_status"
            with_expiration_date
            with_business
            :with_how_often="filter_how_often"
            @reset="get"
          >
          </business-coupon-filters>
        </v-col>
      </v-row>

      <!-- Loading -->
      <loading v-if="request.isLoading()" :count="loader_count"/>

      <coupons-table :usage="usage" :data="request.data" :with_business_name="id === null"></coupons-table>
    </v-card-text>
  </v-card>
</template>

<script>

import Loading from '@/components/app/Loading'
import { GetRequest } from '@/models/GetRequest'
import Space from '@/components/app/Space'
import ItemsLimit from '@/components/items/ItemsLimit'
import CouponSearch from '@/components/coupons/CouponSearch'
import CouponsTable from '@/components/coupons/CouponsTable'
import BusinessCouponFilters from '@/components/coupons/BusinessCouponFilters'
import CustomerCouponFilters from '@/components/coupons/CustomerCouponFilters'

export default {
  name: 'Coupons',
  components: {
    CustomerCouponFilters,
    BusinessCouponFilters, CouponsTable, CouponSearch, ItemsLimit, Space, Loading
  },
  props: {
    id: {
      default: null,
      type: String
    },
    usage: {
      default: 'business',
      type: String
    },
    loader_count: {
      default: 12,
      type: [String, Number]
    },
    add_is_visible: {
      default: false,
      type: Boolean
    },
    hide_actions: {
      default: false,
      type: Boolean
    },
    hide_filters: {
      default: false,
      type: Boolean
    },
    filter_status: {
      default: true,
      type: Boolean
    },
    filter_how_often: {
      default: true,
      type: Boolean
    },
    limit: {
      default: 5,
      type: [String, Number]
    },
    sort: {
      default: 'date_created',
      type: String
    },
    fields: {
      default: '*',
      type: String
    },
    api_key: {
      default: 'get_business_coupons',
      type: String
    }

  },
  data() {
    return {
      request: new GetRequest(),
      show_filters: false,
      status: null
    }
  },
  computed: {},
  mounted() {
    this.request = new GetRequest(this.api_key)
    this.request.setParam('meta', 'filter_count')
    this.get()
  },
  methods: {

    get() {
      this.request.reset()

      this.setFilters()
      this.setQueries()

      this.request.setLimit(this.limit)
      this.request.setNotIn('id', this.without)
      this.request.setSort(this.sort)
      this.request.setFields(this.fields)
      this.request.get()
    },
    setFilters() {
      if (this.status) this.request.addFilter('status', this.status)
      if (this.usage === 'business')
        this.request.addFilter('business_id', this.id, '_eq')
      if (this.usage === 'customer')
        this.request.addFilter('customer_id', this.id, '_eq')
    },
    setQueries() {
      try {
        const queries = this.$route.query

        if (!queries) return

        if (queries.status) this.request.addFilter('status', queries.status)
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
