<template>
  <div>
    <v-form ref="form" v-model="isFormValid" lazy-validation @submit.prevent="request.post()">

      <v-text-field
        v-model="request.body.email"
        :rules="[rules.required]"
        :validate-on-blur="false"
        :label="__('login.email')"
        :name="__('email')"
        outlined
      ></v-text-field>

      <v-text-field
        v-model="request.body.password"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required]"
        :type="showPassword ? 'text' : 'password'"
        :label="__('login.password')"
        :name="__('password')"
        outlined
        @click:append="showPassword = !showPassword"
      ></v-text-field>

      <v-btn
        :loading="request.isLoading()"
        type="submit"
        :disabled="request.isLoading()"
        block
        x-large
        color="primary"
      >{{ __('Login') }}
      </v-btn>

      <div class="mt-5">
        <router-link to="/auth/forgot-password">
          {{ __('ForgotPassword') }}
        </router-link>
      </div>
    </v-form>
  </div>
</template>

<script>
import { PostRequest } from '@/models/PostRequest'

export default {
  name: 'LoginForm',
  components: {},
  props: {
    request: {
      type: PostRequest,
      default: new PostRequest()
    }
  },
  data() {
    return {
      // sign in buttons
      isSignInDisabled: false,

      // form
      isFormValid: true,
      showPassword: false,

      rules: {
        required: (value) => (value && Boolean(value)) || 'Required'
      }
    }
  },
  methods: {}
}
</script>
