<template>
  <div>
    <EditModal
      :title="__('EditCustomer')"
      v-bind="$attrs"
      @input="e => $emit('input', e)"
    >
      <users-edit
        :id="data.id"
        with_phone_number
        @then="e => $emit('then', e)"
      ></users-edit>
    </EditModal>
  </div>
</template>

<script>

import UsersEdit from '@/components/users/UsersEdit'
import EditModal from '@/components/modal/EditModal'

export default {
  name: 'CustomerEdit',
  components: { EditModal, UsersEdit },
  props: {
    data: {
      default: null,
      type: Object
    },
    fields: {
      default: '*',
      type: String
    }
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {
  },
  methods: {}
}
</script>
