<template>
  <div class="d-sm-flex">

    <template v-if="with_expiration_date">
      <date-range-selector
        :title="__('Expiration')"
        :value="value_expiration_date"
        @input="(e) => change(e, 'expiration_date')"
      />
      <space/>
    </template>

    <template v-if="with_business">
      <business-selector :value="value_business_id" @input="(e) => change(e, 'business_id')"/>
      <space/>
    </template>

    <div class="pt-6">
      <v-btn class="border-light rounded-lg" large outlined @click="reset">
        {{ __('Reset') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import Space from '@/components/app/Space'
import DateRangeSelector from '@/components/selectors/DateRangeSelector'
import { GetRequest } from '@/models/GetRequest'
import BusinessSelector from '@/components/selectors/BusinessSelector'

export default {
  name: 'CustomerCouponFilters',
  components: {
    BusinessSelector, DateRangeSelector, Space
  },
  props: {
    request: {
      type: GetRequest,
      default: new GetRequest()
    },
    with_expiration_date: {
      default: false,
      type: Boolean
    },
    with_business: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      filter: {},
      value_expiration_date: null,
      value_business_id: null
    }
  },
  mounted() {
  },
  methods: {
    reset() {
      this.value_expiration_date = null
      this.value_business_id = null
      this.request.setThen()
      this.$emit('reset')
    },
    change(value, key) {
      try {
        let operator = '_eq'

        if (key === 'expiration_date') {
          this.value_expiration_date = value
          operator = '_between'
          key = 'coupon_id'
          value = { 'expiration_date': { '_between': value } }
        }

        if (key === 'business_id') {
          this.value_business_id = value
          key = 'coupon_id'
          value = { 'business_id': { 'id': { '_eq': value } } }
        }

        if (!value) this.request.removeFilter(key)
        else this.request.addFilter(key, value, operator)
        this.request.reload()
      } catch (e) {
        console.log(e)
      }
    },
    getDefault(key) {
      try {
        return this.request.getFilter(key)
      } catch (e) {
        return null
      }
    }
  }
}
</script>

<style scoped>

</style>
