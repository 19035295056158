<template>
  <v-card class="w-full">
    <v-card-text v-if="data">
      <v-row>
        <v-col cols="3">
          <assets-image :data="data.image">
            <v-btn icon class="primary" small disabled>
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </assets-image>
        </v-col>
        <v-col cols="9">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title>
                {{ data.title }}
                <v-list-item-subtitle>
                  <strong>{{ __('Category') }}</strong>: {{ data.business_id.category.title }}
                </v-list-item-subtitle>
              </v-list-item-title>
              <div class="mt-3">
                <strong>{{ __('Conditions') }}</strong>:
                <span v-for="condition in data.conditions" :key="'condition-'+condition">{{ condition }}, </span>
              </div>
              <div class="mt-3">
                <strong>{{ __('Content') }}</strong>:{{ data.content }}
              </div>
              <div class="mt-3">
                <strong>{{ __('ItemHoldingTime') }}</strong>: {{ data.item_holding_time }}
              </div>
              <div class="mt-3">
                <strong>{{ __('ExpireDate') }}</strong>:
                <date-text :data="data.expiration_date"></date-text>
              </div>
              <div class="mt-3">
                <strong>{{ __('DiscountRate') }}</strong>:
                {{ data.discount_rate + '%' }}
              </div>
              <div class="mt-3">
                <strong>{{ __('HowManyAvailable&Used') }}</strong>:
                {{ data.total_count + ' / ' + data.used_count }}
              </div>
            </v-list-item-content>
            <v-list-item-action-text>
              <v-chip small :color="checkStatus(data) ? 'success' : 'error'">
                <v-icon v-if="checkStatus(data)">mdi-check</v-icon>
                <v-icon v-else>mdi-close</v-icon>
              </v-chip>
            </v-list-item-action-text>
          </v-list-item>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions v-if="data">
      <v-btn disabled>{{ __('Edit') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import AssetsImage from '@/components/assets/Image'
import DateText from '@/components/app/DateText'

export default {
  name: 'Limited',
  components: { DateText, AssetsImage },
  props: {
    data: {
      default: null,
      type: Object
    }
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {
  },
  methods: {

    checkStatus(item) {
      if (!item.expiration_date) return false
      const now = new Date().getTime()
      const status = new Date(item.expiration_date).getTime()

      return now < status
    }
  }
}
</script>

<style scoped>

</style>
