<template>
  <div class="full-width">
    <div v-if="!hide_label" class="font-weight-bold">{{ title || __('City') }}</div>
    <v-select
      :items="items"
      :value="value"
      class="custom-selector-border rounded-lg"
      dense
      outlined
      @input="(e) => $emit('input', e)"
    ></v-select>
  </div>
</template>

<script>

import { GetRequest } from '@/models/GetRequest'

export default {
  name: 'CityFilter',
  props: {
    value: {
      default: null,
      type: String
    },
    hide_label: {
      default: false,
      type: Boolean
    },
    title: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      request: new GetRequest('get_businesses'),
      items: [
        {
          text: this.__('All'),
          value: null
        }
      ]
    }
  },
  mounted() {
    this.get()
  },
  methods: {
    reset() {
      this.$emit('reset')
    },
    get() {
      try {
        this.request.addFilter('city', true, '_nnull')
        this.request.setGroupBy('city')
        this.request.setSort('city')
        this.request.setThen(this.itemFormatter)
        this.request.get()
      } catch (e) {
        console.log(e)
      }
    },
    itemFormatter(result) {
      if (result.hasOwnProperty('data')) {
        result.data.forEach((obj) => this.items.push({
          value: obj.city,
          text: this.__(obj.city)
        }))
      }
    }
  }
}
</script>

<style scoped>

</style>
