<template>
  <not-found-data v-if="!data || (data.length < 1)" style="padding-top: 90px !important;"/>
  <v-data-table
    v-else
    :headers="headers"
    :items="data"
    :items-per-page="15"
    class="elevation-1"
  >
    <template v-slot:item.last_access="{ item }">
      <date-text :data="item.last_access"></date-text>
    </template>
    <template v-slot:item.avatar="{ item }">
      <assets-image width="50" :data="item.avatar"></assets-image>
    </template>
    <template v-slot:item.actions="{ item }">
      <user-menu
        :usage="usage"
        :fields="fields"
        :data="item"
        :hide_view="hide_view"
        :hide_edit="hide_edit"
        @then="updateData"
      />
    </template>
  </v-data-table>
</template>

<script>
import AssetsImage from '@/components/assets/Image'
import UserMenu from '@/components/users/UserMenu'
import NotFoundData from '@/components/app/NotFoundData'
import DateText from '@/components/app/DateText'

export default {
  name: 'UsersTable',
  components: { DateText, NotFoundData, UserMenu, AssetsImage },
  props: {
    data: {
      type: Array,
      default: null
    },
    usage: {
      default: 'operator',
      type: String
    },
    hide_view: {
      default: false,
      type: Boolean
    },
    hide_edit: {
      default: false,
      type: Boolean
    },
    fields: {
      default: '*',
      type: String
    }
  },
  computed: {
    headers() {
      if (this.usage === 'customer') {
        return this.customerHeaders()
      } else {
        return this.operatorHeaders()
      }
    }
  },
  methods: {

    customerHeaders() {
      return [
        {
          text: this.__('Image'),
          value: 'avatar',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('FirstName'),
          value: 'first_name',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('LastName'),
          value: 'last_name',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('City'),
          value: 'city',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('Email'),
          value: 'email',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('LastSignIn'),
          value: 'last_access',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('Status'),
          value: 'status',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('actions'),
          value: 'actions',
          align: 'start',
          sortable: false
        }
      ]
    },

    operatorHeaders() {
      return [
        {
          text: this.__('Image'),
          value: 'avatar',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('FirstName'),
          value: 'first_name',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('LastName'),
          value: 'last_name',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('Status'),
          value: 'status',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('Role'),
          value: 'role.name',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('LastSignIn'),
          value: 'last_access',
          align: 'start',
          sortable: false
        },
        // {
        //   text: this.__('City'),
        //   value: 'city',
        //   align: 'start',
        //   sortable: false
        // },
        {
          text: this.__('actions'),
          value: 'actions',
          align: 'start',
          sortable: false
        }
      ]
    },
    updateData(result) {
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].hasOwnProperty('id')) {
          if (this.data[i].id === result.id) {
            this.data.splice(i, 1)
            // this.data[i] = result
            this.data.unshift(result)
          }
        }
      }
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped>

</style>
