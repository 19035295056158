<template>
  <div>
    <referrals add_is_visible></referrals>
  </div>
</template>

<script>
import Referrals from '@/components/referrals/Referrals'

export default {
  name: 'ReferralsPage',
  components: { Referrals },
  data() {
    return {}
  },
  methods: {}
}
</script>
