<template>
  <v-card class="text-center w-full">
    <v-card-text>
      <v-row v-if="!hide_actions">
        <v-col class="d-flex justify-space-between">
          <div class="d-flex width-full">
            <!-- Search -->
            <coupon-search :id="id" :fields="fields" :request="request" usage="used"></coupon-search>
          </div>

          <space/>

          <div class="d-flex">
            <!-- Limit -->
            <items-limit :request="request"/>
          </div>

        </v-col>
      </v-row>

      <!-- Loading -->
      <loading v-if="request.isLoading()" :count="loader_count"/>

      <coupons-table usage="used" :data="request.data"></coupons-table>
    </v-card-text>
  </v-card>
</template>

<script>

import Loading from '@/components/app/Loading'
import { GetRequest } from '@/models/GetRequest'
import Space from '@/components/app/Space'
import ItemsLimit from '@/components/items/ItemsLimit'
import CouponSearch from '@/components/coupons/CouponSearch'
import CouponsTable from '@/components/coupons/CouponsTable'
import BusinessCouponFilters from '@/components/coupons/BusinessCouponFilters'
import CustomerCouponFilters from '@/components/coupons/CustomerCouponFilters'

export default {
  name: 'UsedCoupons',
  components: {
    CouponsTable, CouponSearch, ItemsLimit, Space, Loading
  },
  props: {
    id: {
      default: null,
      type: String
    },
    loader_count: {
      default: 12,
      type: [String, Number]
    },
    hide_actions: {
      default: false,
      type: Boolean
    },
    limit: {
      default: 5,
      type: [String, Number]
    },
    sort: {
      default: 'date_created',
      type: String
    },
    fields: {
      default: '*,customer_id.user_id.first_name,customer_id.user_id.last_name,customer_id.user_id.email',
      type: String
    }

  },
  data() {
    return {
      request: new GetRequest('get_customer_coupons'),
      show_filters: false,
      status: null
    }
  },
  computed: {},
  mounted() {
    this.request.setParam('meta', 'filter_count')
    this.get()
  },
  methods: {

    get() {
      this.request.reset()

      this.setFilters()
      this.setQueries()

      this.request.setLimit(this.limit)
      this.request.setNotIn('id', this.without)
      this.request.setSort(this.sort)
      this.request.setFields(this.fields)
      this.request.get()
    },
    setFilters() {
      this.request.addFilter('coupon_id', this.id, '_eq')
      if (this.status) this.request.addFilter('status', this.status)
    },
    setQueries() {
      try {
        const queries = this.$route.query

        if (!queries) return

        if (queries.status) this.request.addFilter('status', queries.status)
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
