/**
 * import packages
 */
import Vue from 'vue'
import { _t } from '@/tools/Utils'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

/**
 * import layouts
 */
import AuthLayout from '@/layouts/AuthLayout'
import PanelLayout from '@/layouts/PanelLayout'
import DefaultLayout from '@/layouts/DefaultLayout'

/**
 * import pages
 */
import LoginPage from '@/pages/auth/LoginPage'
import DashboardPage from '@/pages/dashboard/DashboardPage'
import NotFoundPage from '@/pages/errors/NotFoundPage'
import OperatorsPage from '@/pages/operators/OperatorsPage'
import BusinessOwnersPage from '@/pages/businesses/BusinessOwnersPage'
import BusinessesPage from '@/pages/businesses/BusinessesPage'
import NewBusinessPage from '@/pages/businesses/NewBusinessPage'
import BusinessPage from '@/pages/businesses/BusinessPage'
import CustomersPage from '@/pages/customers/CustomersPage'
import CustomerPage from '@/pages/customers/CustomerPage'
import NewOperator from '@/components/operators/NewOperator'
import EditOperatorPage from '@/pages/operators/EditOperatorPage'
import UserPage from '@/pages/users/UserPage'
import CouponsPage from '@/pages/coupons/CouponsPage'
import LimitedsPage from '@/pages/limiteds/LimitedsPage'
import FeedbacksPage from '@/pages/feedbacks/FeedbacksPage'
import ReferralsPage from '@/pages/referrals/ReferralsPage'
import SubscriptionsPage from '@/pages/subscriptions/SubscriptionsPage'
import UpdatePage from '@/pages/businesses/UpdatePage'
import CouponPage from '@/pages/coupons/CouponPage'
import LimitedPage from '@/pages/limiteds/LimitedPage'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: AuthLayout,
    redirect: { name: 'Login' },
    children: [
      {
        path: '/login',
        name: 'Login',
        component: LoginPage,
        meta: {
          title: _t('inLo'),
          requiresGuest: true,
          headTags: [
            {
              name: 'description',
              content: _t('inLoDescription')
            },
            {
              property: 'og:description',
              content: _t('inLoOgDescription')
            },
            {
              name: 'robots',
              content: 'index, follow'
            },
            {
              'http-equiv': 'Content-Type',
              content: 'text/html;charset=UTF-8'
            }
          ]
        }
      }
    ]
  },

  {
    path: '/',
    component: PanelLayout,
    redirect: { name: 'Dashboard' },
    meta: {
      title: _t('Dashboard'),
      requiresAuth: true,
      requiresRole: true
    },
    children: [
      {
        path: '/dashboard/',
        name: 'Dashboard',
        component: DashboardPage
      },
      {
        path: '/user/:id',
        name: 'User',
        component: UserPage
      },
      {
        path: 'operators',
        redirect: { name: 'Operators' },
        component: DefaultLayout,
        children: [
          {
            path: '/operators/list',
            name: 'Operators',
            component: OperatorsPage
          },
          {
            path: '/operators/new',
            name: 'NewOperator',
            component: NewOperator
          },
          {
            path: '/operators/edit/:id',
            name: 'EditOperator',
            component: EditOperatorPage
          }
        ]
      },
      {
        path: 'business',
        redirect: { name: 'BusinessOwners' },
        component: DefaultLayout,
        children: [
          {
            path: '/business/owners/list',
            name: 'BusinessOwners',
            component: BusinessOwnersPage
          },
          {
            path: '/business/list',
            name: 'Businesses',
            component: BusinessesPage
          },
          {
            path: '/business/new',
            name: 'NewBusiness',
            component: NewBusinessPage
          },
          {
            path: '/business/update/:id',
            name: 'Update',
            component: UpdatePage
          },
          {
            path: '/business/:id',
            name: 'Business',
            component: BusinessPage
          }
        ]
      },
      {
        path: 'customers',
        redirect: { name: 'Customers' },
        component: DefaultLayout,
        children: [
          {
            path: '/customers/list',
            name: 'Customers',
            component: CustomersPage
          },
          {
            path: '/customer/:id',
            name: 'Customer',
            component: CustomerPage
          }
        ]
      },
      {
        path: 'coupons',
        redirect: { name: 'Coupons' },
        component: DefaultLayout,
        children: [
          {
            path: '/coupons/list',
            name: 'Coupons',
            component: CouponsPage
          },
          {
            path: '/coupons/:id',
            name: 'Coupon',
            component: CouponPage
          }
        ]
      },
      {
        path: 'limited',
        redirect: { name: 'Limiteds' },
        component: DefaultLayout,
        children: [
          {
            path: '/limited/list',
            name: 'Limiteds',
            component: LimitedsPage
          },
          {
            path: '/limited/:id',
            name: 'Limited',
            component: LimitedPage
          }
        ]
      },
      {
        path: 'feedbacks',
        redirect: { name: 'Feedbacks' },
        component: DefaultLayout,
        children: [
          {
            path: '/feedbacks/list',
            name: 'Feedbacks',
            component: FeedbacksPage
          }
        ]
      },
      {
        path: 'referrals',
        redirect: { name: 'Referrals' },
        component: DefaultLayout,
        children: [
          {
            path: '/referrals/list',
            name: 'Referrals',
            component: ReferralsPage
          }
        ]
      },
      {
        path: 'subscriptions',
        redirect: { name: 'Subscriptions' },
        component: DefaultLayout,
        children: [
          {
            path: '/subscriptions/list',
            name: 'Subscriptions',
            component: SubscriptionsPage
          }
        ]
      }
    ]
  },
  {
    path: '*',
    name: '404',
    component: NotFoundPage,
    meta: {
      title: '404'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes
})

export default router
