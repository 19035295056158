<template>
  <div>
    <new-business></new-business>
  </div>
</template>

<script>
import NewBusiness from '@/components/businesses/NewBusiness'

export default {
  name: 'NewBusinessPage',
  components: { NewBusiness },
  data() {
    return {}
  },
  methods: {}
}
</script>
