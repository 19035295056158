<template>
  <div>
    <confirm
      v-show="api_key.length"
      :value="value"
      v-bind="$attrs"
      @then="submit"
    >
    </confirm>
  </div>
</template>

<script>

import Confirm from '@/components/modal/Confirm'
import { DeleteRequest } from '@/models/DeleteRequest'

export default {
  name: 'RemoveModal',
  components: { Confirm },
  props: {
    data: {
      default: null,
      type: Object,
      require: true
    },
    value: {
      default: null,
      type: Boolean
    },
    api_key: {
      default: '',
      type: String
    }
  },
  data: () => {
    return {
      request: new DeleteRequest()
    }
  },
  mounted() {
    if (this.api_key.length > 1) {
      this.request = new DeleteRequest(this.api_key)
      this.request.setApiParams([this.data.id])
    }
  },
  methods: {
    setBody() {
      this.request.setThen(this.submitted)
      this.request.setBody(
        {
          status: 'archived'
        }
      )
    },
    submit(e) {
      if (e) {
        this.setBody()
        this.request.delete()
      }
      this.$emit('input', e)
    },
    submitted() {
      this.data.status = 'archived'
      this.$emit('then', this.data)
    }
  }
}
</script>

<style scoped>

</style>
