<template>
  <div>
    <business-details :id="$route.params.id"></business-details>
  </div>
</template>

<script>
import BusinessDetails from '@/components/businesses/BusinessDetails'

export default {
  name: 'BusinessPage',
  components: { BusinessDetails },
  data() {
    return {}
  },
  methods: {}
}
</script>
