<template>
  <v-card class="w-full">
    <v-card-title>
      Limited Info
    </v-card-title>
    <not-found-data v-if="!request.data"></not-found-data>
    <v-card-text v-else>
      <v-row>
        <v-col cols="12">
          <limited :data="request.data"></limited>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <used-limiteds :id="id"></used-limiteds>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

import { GetRequest } from '@/models/GetRequest'
import NotFoundData from '@/components/app/NotFoundData'
import Limited from '@/components/limiteds/Limited'
import UsedLimiteds from '@/components/limiteds/UsedLimiteds'

export default {
  name: 'LimitedDetails',
  components: { UsedLimiteds, Limited, NotFoundData },
  props: {
    id: {
      default: '',
      type: String,
      require: true
    }
  },
  data() {
    return {
      request: new GetRequest('get_business_limited', [this.id])
    }
  },
  computed: {},
  mounted() {
    this.get()
  },
  methods: {

    checkStatus(item) {
      if (!item.expiration_date) return false
      const now = new Date().getTime()
      const status = new Date(item.expiration_date).getTime()

      return now < status
    },
    get() {
      this.request.reset()

      this.request.setParam('deep', '{"used_limited_items": {"_limit": 5, "_page": 1}}')

      this.request.setFields('*,business_id.*,business_id.category.title,used_limited_items.*,' +
        'used_limited_items.customer_id.user_id.first_name,used_limited_items.customer_id.user_id.last_name,' +
        'used_limited_items.customer_id.user_id.email')
      this.request.row()
    }
  }
}
</script>

<style scoped>

</style>
