<template>
  <div>
    <not-found-data v-if="!data || (data.length < 1)" style="padding-top: 90px !important;"/>
    <v-data-table
      v-else
      :headers="headers"
      :items="data"
      :items-per-page="15"
    >
      <template v-slot:item.owner_id.user_id.last_access="{ item }">
        <date-text :data="item.owner_id.user_id.last_access"></date-text>
      </template>
      <template v-slot:item.images="{ item }">
        <div
          @click="openImageDialog(item.images)"
        >
          <assets-images
            v-if="item.images.length>0"
            type="cover"
            width="50"
            height="50"
            :data="item.images"
            carousel
            hide_delimiters
          ></assets-images>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <business-actions :data="item"></business-actions>
      </template>
    </v-data-table>
    <images-modal
      v-model="show_images"
      :data="selected_images"
    ></images-modal>
  </div>
</template>

<script>

import DateText from '@/components/app/DateText'
import AssetsImages from '@/components/assets/AssetsImages'
import BusinessActions from '@/components/businesses/BusinessActions'
import ImagesModal from '@/components/assets/ImagesModal'
import NotFoundData from '@/components/app/NotFoundData'

export default {
  name: 'BusinessTable',
  components: { NotFoundData, ImagesModal, BusinessActions, AssetsImages, DateText },
  props: {
    data: {
      type: Array,
      require: true
    }
  },
  data() {
    return {
      show_images: false,
      selected_images: null
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.__('businessName'),
          value: 'name',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('membership'),
          value: 'membership',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('emailAddress'),
          value: 'owner_id.user_id.email',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('lastSignIn'),
          value: 'owner_id.user_id.last_access',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('phoneNumber'),
          value: 'phone_number',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('image'),
          value: 'images',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('city'),
          value: 'city',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('status'),
          value: 'status',
          align: 'start',
          sortable: false
        },
        {
          text: this.__('actions'),
          value: 'actions',
          align: 'start',
          sortable: false
        }
      ]
    }
  },
  mounted() {
  },
  methods: {
    openImageDialog(images) {
      this.selected_images = images
      this.show_images = !this.show_images
    }
  }
}
</script>
