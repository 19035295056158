<template>
  <div>
    <div v-if="message !== null &&  message.text">
      <v-snackbar
          key="0"
          v-model="show"
          :timeout="3000"
          :left="true"
          :multi-line="60 < (message.text).length"
          :bottom="true"
      >
        {{ message.text }}

        <template v-slot:action>
          <v-btn icon dark @click="show = false" >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      loading: false,
      show: true,
    }
  },
  computed: {
    message() {
      return this.$store.state.message
    }
  },
  watch: {
    '$store.state.message': function () {
      this.show = true;
    }
  },
  mounted() {
  },
  methods: {}
}
</script>

<style>

</style>