<template>
  <v-card class="text-center w-full">
    <v-card-title>
      Business list
    </v-card-title>
    <v-card-text>
      <v-row v-if="!hide_actions">
        <v-col class="d-flex justify-space-between">
          <div class="d-flex width-full">

            <!-- Add Button   -->
            <template v-if="add_is_visible">
              <v-btn
                disabled
                color="primary"
                elevation="0"
                large
                class="hidden-sm-and-down rounded-lg"
                :to="{name:'NewBusiness'}"
              >
                {{ __('createBusiness') }}
              </v-btn>
              <v-btn
                disabled
                color="primary"
                fab
                elevation="0"
                small
                class="hidden-md-and-up"
                :to="{name:'NewBusiness'}"
                :title="__('createBusiness')"
              >
                <v-icon dark>mdi-plus</v-icon>
              </v-btn>
            </template>

            <space/>

            <!-- Search -->
            <business-search :request="request"></business-search>
          </div>

          <space/>

          <div class="d-flex">
            <!-- Limit -->
            <items-limit :request="request"/>
          </div>

          <!--   Filters button     -->
          <v-btn
            v-if="!hide_filters"
            class="rounded-lg border-light"
            outlined
            large
            @click="show_filters = !show_filters"
          >
            <v-icon left>mdi-filter-variant</v-icon>
            {{ __("Filters") }}
          </v-btn>
        </v-col>
      </v-row>

      <!-- Filters -->
      <v-row v-show="show_filters" v-if="!hide_filters">
        <v-col>
          <business-filters
            :request="request"
            :with_date_login="with_date_login"
            :with_date_register="with_date_register"
            :with_status="status === null"
            @reset="get"
          >
          </business-filters>
        </v-col>
      </v-row>

      <v-tabs>
        <v-tab @click="allBusiness">{{ __('AllBusiness') }}</v-tab>
        <v-tab @click="newBusiness">{{ __('NewBusiness') }}</v-tab>
      </v-tabs>
      <!-- Loading -->
      <loading v-if="request.isLoading()" :count="loader_count"/>

      <business-table :data="request.data"></business-table>
    </v-card-text>
  </v-card>
</template>

<script>

import BusinessTable from '@/components/businesses/BusinessTable'
import Loading from '@/components/app/Loading'
import BusinessFilters from '@/components/businesses/BusinessFilters'
import { GetRequest } from '@/models/GetRequest'
import BusinessSearch from '@/components/businesses/BusinessSearch'
import Space from '@/components/app/Space'
import ItemsLimit from '@/components/items/ItemsLimit'

export default {
  name: 'Businesses',
  components: { ItemsLimit, Space, BusinessSearch, BusinessFilters, Loading, BusinessTable },
  props: {

    loader_count: {
      default: 12,
      type: [String, Number]
    },
    add_is_visible: {
      default: false,
      type: Boolean
    },
    hide_actions: {
      default: false,
      type: Boolean
    },
    hide_filters: {
      default: false,
      type: Boolean
    },
    filter_status: {
      default: true,
      type: Boolean
    },
    with_date_login: {
      default: false,
      type: Boolean
    },
    with_date_register: {
      default: false,
      type: Boolean
    },
    limit: {
      default: null,
      type: [String, Number]
    },
    sort: {
      default: 'date_created',
      type: String
    },
    fields: {
      default: 'id,date_created,name,membership,city,status,owner_id.picture_id,' +
        'owner_id.user_id.email,owner_id.user_id.avatar,owner_id.user_id.phone_number,' +
        'owner_id.user_id.last_access,images.*,phone_number',
      type: String
    }
  },
  data() {
    return {
      request: new GetRequest('get_businesses'),
      show_filters: false,
      status: null
    }
  },
  computed: {},
  mounted() {
    this.get()
  },
  methods: {
    allBusiness() {
      this.status = null
      this.get()
    },
    newBusiness() {
      this.status = 'pending'
      this.get()
    },

    get() {
      this.request.reset()

      this.setFilters()
      this.setQueries()

      this.request.setLimit(this.limit)
      this.request.setNotIn('id', this.without)
      this.request.setSort(this.sort)
      this.request.setFields(this.fields)
      this.request.get()
    },
    setFilters() {
      if (this.status) this.request.addFilter('status', this.status)
    },
    setQueries() {
      try {
        const queries = this.$route.query

        if (!queries) return

        if (queries.status) this.request.addFilter('status', queries.status)
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
