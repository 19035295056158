<template>
  <div>
    <customer-details :id="$route.params.id"></customer-details>
  </div>
</template>

<script>
import CustomerDetails from '@/components/customers/CustomerDetails'

export default {
  name: 'CustomerPage',
  components: { CustomerDetails },
  data() {
    return {}
  },
  methods: {}
}
</script>
